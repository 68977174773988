import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate, useLocation } from '@reach/router';
import axios from 'axios';
import moment from 'moment';
import Handlebars from "handlebars";
import { Box, Button, CircularProgress, Grid, Hidden, IconButton, makeStyles, MobileStepper, Step, StepLabel, Stepper, Typography, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, InputLabel } from '@material-ui/core';
import { useQuote } from '../../hooks/useQuote';
import TagsNavigator from './components/tagsNavigator';
import WizardTagsNavigator from './components/wizardTagsNavigator';
import QuoteContainer from './quoteContainer';
import { Paper } from '@material-ui/core';
import { showError } from '../../actions/notificationActions';
import { getWithRemote, getWithRemoteApi } from '../../actions/dynamicActions';
import Utils from '../../utils/utils';
import { Add, ArrowBackIos, ArrowForwardIos, Cancel, RemoveCircleOutline } from '@material-ui/icons';
import { isValidEmail } from '../../utils';
import { postSaveQuotation } from '../../actions/serviceActions';
import { AUTOS } from '../../utils/risksTypes';
import { withStyles } from '@material-ui/styles';
import CustomWizard from './components/CustomWizard';

const useStyles = makeStyles((theme) => ({
  appContainer: {
    paddingLeft: 8,
    paddingRight: 8,
    width: '100%'
  },
  hide: {
    display: 'none'
  },
  loader: {
    textAlign: 'center',
    width: '100%',
    position: 'fixed',
    zIndex: 9999,
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  mobileTitle: {
    textAlign: 'center',
    marginTop: 20,
    "& > p": {
      fontSize: 18,
      lineHeight: "35px",
      letterSpacing: 0.15,
      color: "#141B6A",
      fontWeight: 500
    }
  },
  modal: {
    borderRadius: 15
  },
  modalMessage: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 13,
    textAlign: 'center',
    color: '#4B4B4B',
  },
  itemBack: {
    display: "flex",
    marginLeft: 10,
    "& h2": {
      fontSize: 16
    }
  },
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    marginBottom: 18,
    letterSpacing: 0.15,
    '& svg': {
      marginRight: 6,
      fontSize: 16
    },
    '& label': {
      fontSize: 20,
      color: theme.palette.secondary.main,
      fontWeight: 600,
      overflow: 'unset',
      maxWidth: '100%',
      whiteSpace: 'break-spaces'
    },
  }
}));

const StyledMobileStepper = withStyles({
  root: {
    padding: '1rem',
    flexGrow: 1,
    background: 'transparent'
  },
  dots: {
    width: '100%',
    justifyContent: 'space-between'
  }
})(MobileStepper);

const StyledDialogTitle = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    letterSpacing: 0.15,
    fontSize: 20,
    textAlign: "center"
  }
}))(DialogTitle);

const StyledDialogActions = withStyles({
  root: {
    justifyContent: 'space-between'
  }
})(DialogActions);

const Wizard = ({ data, insurance, formBuilderId, riskType, saved }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [index, setIndex] = useState(0);
  const [fillingExtraFields, setFillingExtraFields] = useState(false);
  const [extraFields, setExtraFields] = useState([]);
  const [extraFieldsOuterIndex, setExtraFieldsOuterIndex] = useState(0);
  const [extraFieldsInnerIndex, setExtraFieldsInnerIndex] = useState(0);
  const [extraFieldsGroupIndex, setExtraFieldsGroupIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isNextButtonValid, setIsNextButtonValid] = useState(false);
  const [preSelectedValue, setPreSelectedValue] = useState(null);
  const quote = useQuote(riskType);
  const dynamicReducer = useSelector(state => state.dynamicReducer);
  const location = useLocation();
  const { quotation: { quotationScreenValues } } = quote;

  const steps = data.groups;
  let extraFieldsRetrieved = '';
  let multipleFieldsTemplate = [];
  const isEmbed = location.pathname.includes('embed');

  useEffect(() => {
    steps.forEach((group, i) => {
      if (group.multiple) {
        group.groupIndex = multipleFieldsTemplate.length;
        multipleFieldsTemplate = multipleFieldsTemplate.concat(group.fields.map(f => ({ groupName: group.groupName, ...f })));
      }

      if (group.groupName === 'lead') {
        group.groupLabel = 'Contacto';
      }
      console.log('Esto se ejecuta otra vez?');
      /* group.fields.forEach(field => {
        field.completed = false;
      }); */
    });

    if (steps.length > 0 && saved && !data.extraFields) {
      const allIsNull = steps.reduce((total, group) => group.fields.every(field => field.value === null));

      if (allIsNull && quotationScreenValues && !Utils.isEmptyObject(quotationScreenValues)) {
        steps.forEach(group => {
          group.fields.map(field => {
            const property = field.name;
            field.value = quotationScreenValues[property];
          });
        });

        const items = Array.isArray(quotationScreenValues.items) ? [...quotationScreenValues.items] : [];
        extraFieldsRetrieved = items.map((item) => {
          const _item = Object.assign({}, item);
          const templates = [...multipleFieldsTemplate];

          const result = templates.map((group) => {
            return group.map((temp) => {
              const _temp = Object.assign({}, temp);
              const property = _temp.name;
              if (_temp.groupName === _item.groupName) {
                _temp.value = _item[property];
              }
              return _temp;
            });
          });

          return result;
        });
        console.log('Extra fields retrieved', extraFieldsRetrieved);
        console.log('DynamicForm data: ', data);

        data.extraFields = extraFieldsRetrieved;
        sessionStorage.setItem(`quotation::${riskType}`, JSON.stringify(data));
        setExtraFields(data.extraFields);
        setActiveStep(steps.length - 1);
        setIndex(steps[steps.length - 1].fields.length - 1);
      }
    }
  }, []);


  useEffect(() => {
    if (saved) {
      const lastStep = steps[steps.length - 1];
      const lastTag = lastStep.fields[lastStep.fields.length - 1];
      if (lastTag.controlType !== 'chip') {
        setInputValue(lastTag.value);
      }
      if (data.extraFields) {
        setExtraFields(data.extraFields);
      }
      setActiveStep(steps.length - 1);
      setIndex(lastStep.fields.length - 1);
    } else {
      let initialExtraFields = [];
      data.groups.filter(g => g.multiple).forEach(_ => {
        initialExtraFields.push([]);
      });
      setExtraFields(initialExtraFields);
    }
  }, [saved]);

  useEffect(() => {
    if (activeStep > 0 && activeStep < steps.length) {
      if (steps[activeStep].multiple) {
        if (steps[activeStep].min > 0) {
          handleFillExtraFields(steps[activeStep].groupIndex);
        }
      }
    }
  }, [activeStep]);

  const handleShowWarningMessage = () => {
    setShowWarningMessage(true);
  };

  const handleCloseWarningMessage = () => {
    setShowWarningMessage(false);
  };

  const handleAcceptWarningMessage = () => {
    reset();
    handleCloseWarningMessage();
  };

  const handleCloseTag = async (tag) => {
    console.log('close tag', tag);
    const tagIndex = steps[activeStep].fields.findIndex(f => f.name === tag.name);
    steps[activeStep].fields.map(f => {
      const _f = f;
      if (tagIndex <= _f.order) {
        _f.value = null;
        // _f.completed = false;

        if (_f.remote) {
          _f.options = [];
        }
      }

      return _f;
    });

    if (steps[activeStep].fields[tagIndex].remote) {
      const dependencies = steps[activeStep].fields[tagIndex].dependency.map(dependency => {
        const dependencyField = steps.map(group => {
          return group.fields.find(field => field.name === dependency);
        }).filter(group => group);

        console.log(dependencyField);
        return dependencyField.length > 0 && dependencyField[0].value;
      }).join('/');
      const remote = `${steps[tagIndex].remote}${dependencies}`;

      await dispatch(getWithRemote(remote));
    }

    if (tag.controlType !== 'chip') {
      setInputValue(tag.value);
    }
    setIsUpdating(true);
    console.log('Current index', index);
    console.log('Tag index', tagIndex);
    setIndex(tagIndex);
  };

  const handleSelect = async (value, extraField) => {
    if (!extraField) {
      const currentStep = steps[activeStep].fields[index];
      const nextStep = steps[activeStep].fields[index + 1];
      if (nextStep && nextStep.remote) {
        const dependencies = nextStep.dependency.map(dependency => {
          const dependencyField = steps.map(group => {
            return group.fields.find(field => field.name === dependency);
          }).filter(group => group);

          console.log(dependencyField);
          return dependencyField.length > 0 && dependencyField[0].value === null ? (currentStep.name === 'vehicleType' ? (value ? 0 : 1) : value) : dependencyField[0].value;
        }).join('/');

        const remote = `${nextStep.remote}${dependencies}`;

        await dispatch(getWithRemote(remote));
        if (currentStep.remote) {
          currentStep.options = currentStep.options.length > 0 ? dynamicReducer.data.map((d) => {
            const isMainOption = currentStep.options.find(opt => opt === d.name);
            if (isMainOption) {
              return {
                ...d,
                main: true
              };
            }

            return d;
          }) : dynamicReducer.data;
        }
      }

      if (nextStep && !nextStep.remote && currentStep.remote) {
        currentStep.options = currentStep.options.length > 0 ? dynamicReducer.data.map((d) => {
          const isMainOption = currentStep.options.find(opt => opt === d.name);
          if (isMainOption) {
            return {
              ...d,
              main: true
            };
          }

          return d;
        }) : dynamicReducer.data;
      }

      if (!currentStep.controlType.includes('input')) {
        currentStep.value = value;
        // currentStep.completed = true;
        setInputValue('');
        setPreSelectedValue(null);
        setIsUpdating(false);
        if (!isLast()) {
          setIndex(index + 1);
        } else {
          setActiveStep(activeStep + 1);
          setIndex(0);
        }
      } else {
        setInputValue(value);
      }
    } else {
      const currentExtraFieldGroup = extraFields[extraFieldsGroupIndex];
      console.log('Current group', currentExtraFieldGroup);
      const currentField = currentExtraFieldGroup[extraFieldsOuterIndex];
      console.log('Current field', currentField);
      const currentTag = currentField[extraFieldsInnerIndex];
      const nextTag = currentField[extraFieldsInnerIndex + 1];
      console.log(currentTag);

      if (nextTag && nextTag.remote) {
        const dependencies = nextTag.dependency.map(dependency => {
          const dependencyField = steps.map(group => {
            const allFields = group.fields.concat(...currentField);
            return allFields.find(field => field.name === dependency);
          }).filter(group => group);

          console.log('DF', dependencyField);

          return dependencyField.length > 0 && dependencyField[0].value === null ? (currentTag.name === 'vehicleType' ? (value ? 0 : 1) : value) : dependencyField[0].value;
        }).join('/');
        const remote = `${nextTag.remote}${dependencies}`;
        setLoading(true);
        const result = await getWithRemoteApi(remote);
        setLoading(false);
        if (nextTag.remote) {
          nextTag.options = nextTag.options.length > 0 ? (result.data ? result.data : result).map((d) => {
            const isMainOption = nextTag.options.find(opt => opt === d.name);
            if (isMainOption) {
              return {
                ...d,
                main: true
              };
            }

            return d;
          }) : (result.data ? result.data : result);
        }
      }

      if (!currentTag.controlType.includes('input')) {
        currentTag.value = value;
        setInputValue('');
        setPreSelectedValue(null);
        const updatedExtraFieldGroup = currentExtraFieldGroup.map((eF, i) => {
          if (i === extraFieldsOuterIndex) {
            const tags = eF.map((t, i) => {
              if (i === extraFieldsInnerIndex) {
                return currentTag;
              }
              return t;
            });

            return tags;
          }

          return eF;
        });

        if (currentField.length > extraFieldsInnerIndex + 1) {
          setExtraFieldsInnerIndex(extraFieldsInnerIndex + 1);
        } else {
          setFillingExtraFields(false);
          setExtraFieldsInnerIndex(0);
          /* if (steps[activeStep].multiple && (updatedExtraFieldGroup.length >= steps[activeStep].max)) {
            setIndex(index + 1);
          } */
        }

        const updatedExtraFields = extraFields.map((ex, i) => {
          if (i === extraFieldsGroupIndex) {
            return updatedExtraFieldGroup
          }

          return ex;
        });

        setExtraFields(updatedExtraFields);
      } else {
        setInputValue(value);
      }

    }

  };

  const handleCheck = async (value, extraField) => {
    console.log('Handle check');
    const currentStep = steps[activeStep].fields[index];
    console.log('Current step', currentStep);
    if (!extraField) {
      if (currentStep.remote) {
        if (value !== '') {
          console.log('Request from input');
        } else {
          currentStep.value = '';
          setInputValue('');
          if (!isLast()) {
            setIndex(index + 1);
          } else {
            setActiveStep(activeStep + 1);
            setIndex(0);
          }
        }
      } else {
        currentStep.value = value;
        // currentStep.completed = true;
        console.log('Current step after update', currentStep);
        setIsUpdating(false);
        setInputValue('');
        if (!isLast()) {
          setIndex(index + 1);
        } else {
          setActiveStep(activeStep + 1);
          setIndex(0);
        }
      }
    } else {
      const currentExtraFieldGroup = extraFields[extraFieldsGroupIndex];
      const currentField = currentExtraFieldGroup[extraFieldsOuterIndex];
      const currentTag = currentField[extraFieldsInnerIndex];
      currentTag.value = value;
      const updatedExtraFieldGroup = currentExtraFieldGroup.map((eF, i) => {
        if (i === extraFieldsOuterIndex) {
          const tags = eF.map((t, i) => {
            if (i === extraFieldsInnerIndex) {
              return currentTag;
            }
            return t;
          });

          return tags;
        }

        return eF;
      });

      setInputValue('');
      if (currentField.length > extraFieldsInnerIndex + 1) {
        setExtraFieldsInnerIndex(extraFieldsInnerIndex + 1);
      } else {
        setFillingExtraFields(false);
        setExtraFieldsInnerIndex(0);
        /* if (steps[index].type === 'multiple' && (updatedExtraFieldGroup.length >= steps[index].value)) {
          setIndex(index + 1);
        } */
      }

      const updatedExtraFields = extraFields.map((ex, i) => {
        if (i === extraFieldsGroupIndex) {
          return updatedExtraFieldGroup
        }

        return ex;
      });
      setExtraFields(updatedExtraFields);
    }
  };

  const isLast = () => {
    return index === steps[activeStep].fields.length - 1;
  }

  const handlePreSelect = (value) => {
    setPreSelectedValue(value);
  };

  const handleSetOnNext = () => {
    handleNextButtonValid(false);
    if (fillingExtraFields) {
      const currentExtraFieldGroup = extraFields[extraFieldsGroupIndex];
      const currentField = currentExtraFieldGroup[extraFieldsOuterIndex];
      const currentTag = currentField[extraFieldsInnerIndex];
      if (!currentTag.controlType.includes('input')) {
        handleSelect(preSelectedValue, true);
      } else {
        handleCheck(inputValue, true);
      }
    } else {
      const currentStep = steps[activeStep].fields[index];
      if (!currentStep.controlType.includes('input')) {
        handleSelect(preSelectedValue, false);
      } else {
        handleCheck(inputValue, false);
      }
    }
  };

  const handleNextButtonValid = (isValid) => {
    setIsNextButtonValid(isValid);
  };

  const handleFillExtraFields = (index) => {
    console.log('index', index);
    if (fillingExtraFields) {
      const updatedExtraFieldGroup = extraFields.map((curr, i) => {
        if (i === extraFieldsGroupIndex) {
          return curr.slice(0, -1);
        }

        return curr;
      });
      setExtraFields(updatedExtraFieldGroup);
      setExtraFieldsInnerIndex(0);
      setFillingExtraFields(false);
    } else {
      const group = data.groups.filter(gr => gr.multiple);
      const fields = group[index].fields.map(f => ({
        ...f,
        groupName: group[index].groupName,
        value: null
      }));
      const updatedExtraFieldGroup = extraFields.map((curr, i) => {
        if (i === index) {
          return curr.concat([fields]);
        }

        return curr;
      })
      console.log(updatedExtraFieldGroup);
      setExtraFields(updatedExtraFieldGroup);
      setExtraFieldsGroupIndex(index);
      setExtraFieldsOuterIndex(extraFields[index].length);
      setFillingExtraFields(true);
    }
  };

  const handleDeleteExtraField = (index, groupIndex) => {
    const currentGroup = extraFields[groupIndex];
    if (fillingExtraFields) {
      setExtraFieldsInnerIndex(0);
      const updatedFields = currentGroup.map((f, i) => {
        if (i === index) {
          return f.map((_f) => {
            return {
              ..._f,
              value: null
            };
          });
        }

        return f;
      });
      const updatedExtraFieldGroup = extraFields.map((curr, i) => {
        if (i === groupIndex) {
          return updatedFields;
        }

        return curr;
      })
      setExtraFields(updatedExtraFieldGroup);
    } else {
      const updatedExtraFieldGroup = extraFields.map((curr, i) => {
        if (i === groupIndex) {
          return curr.filter((c, j) => j !== index);
        }

        return curr;
      })
      setExtraFields(updatedExtraFieldGroup);
    }
  };

  const handleDeleteExtraFieldTag = (index, groupIndex, innerIndex) => {
    console.log('Outerindex', index);
    const updatedExtraFieldsGroup = extraFields.map((curr, i) => {
      if (i === groupIndex) {
        return curr.map((group, j) => {
          if (j === index) {
            return group.map((field, k) => {
              const _field = field;

              if (k > (innerIndex + 1)) {
                if (_field.remote) {
                  _field.options = _field.options.some(opt => opt.main) ? _field.options.filter(opt => opt.main) : [];
                }
              }

              if (k >= (innerIndex + 1)) {
                _field.value = null;
              }

              return _field;
            });
          };

          return group;
        })
      }

      return curr;
    });
    setExtraFields(updatedExtraFieldsGroup);
    setExtraFieldsGroupIndex(groupIndex);
    setExtraFieldsOuterIndex(index);
    setExtraFieldsInnerIndex(innerIndex + 1);
    setFillingExtraFields(true);
  };

  const goToPrevStep = () => {
    if (steps[activeStep] && steps[activeStep].multiple) {
      const updatedExtraFields = extraFields.map((group, i) => {
        if (i === steps[activeStep].groupIndex) {
          return [];
        }

        return group;
      });

      setExtraFields(updatedExtraFields);
    }
    setActiveStep(activeStep - 1);
    setIndex(steps[activeStep - 1].fields.length - 1);
  };

  const goToNextStep = () => {
    setActiveStep(activeStep + 1);
    if (steps[activeStep + 1]) {
      const stepIndex = steps[activeStep + 1].fields.findIndex(f => f.value === null);
      if (stepIndex === -1) {
        setIndex(steps[activeStep + 1].fields.length - 1);
      } else {
        setIndex(stepIndex);
      }
    }
  }

  const getOverview = () => {
    const overview = [];
    steps.forEach((group, i) => {
      if (!group.multiple) {
        group.fields.forEach(field => {
          if (group.groupName === 'lead') {
            overview.push({
              ...field,
              isLead: true
            });
          } else {
            overview.push(field);
          }
        });
      }
    });

    return overview;
  };

  const extraFieldsValid = (groups) => {
    return extraFields.filter((_, i) => groups[i].min > 0).every((eF, i) => eF.length >= groups[i].min);
  }

  const isValid = () => {
    let conditions = getOverview().filter(step => !step.isLead && step.type !== 'multiple' && !step.isOptional).every(step => step.value !== null);

    if (steps.find(step => step.isLead)) {
      conditions = conditions && isValidEmail(inputValue);
    }

    const multipleGroups = data.groups.filter(g => g.multiple);
    console.log(multipleGroups);
    const isRequired = multipleGroups.some(group => group.min > 0);
    if (multipleGroups && isRequired) {
      conditions = conditions && extraFieldsValid(multipleGroups);
    }
    return conditions;
  };

  const getFormattedValue = (field) => {
    console.log(field.value);
    switch (field.dataType) {
      case 'bool':
      case 'object':
      case 'string': return field.value;
      case 'numeric':
        let value = field.value;
        if (isNaN(value)) {
          value = value.includes(',') ? value.replaceAll(',', '') : value
        }
        return Number(value);
      case 'date': return field.controlType.includes('birthdate') ? moment().diff(field.value, 'years') : field.value;
    };
  };

  const buildQuoteBody = () => {
    const quoteBody = {};
    const overview = getOverview();
    overview.filter(step => !step.isLead && step.type !== 'multiple').forEach(step => {
      if (step.remote) {
        const selectedOption = step.options.find(opt => opt._id === step.value);
        quoteBody[step.name] = selectedOption !== undefined ? selectedOption.name : step.value;
      } else {
        quoteBody[step.name] = getFormattedValue(step);
      }
    });

    quoteBody.riskType = data.riskType;
    quoteBody.insuranceId = insurance;
    quoteBody.quotas = [3, 6, 9, 10];

    if (typeof quoteBody.ano === 'boolean' && riskType.toUpperCase() === AUTOS) {
      quoteBody.producto = quoteBody.ano === true ? 'Todo Riesgo' : 'Perdida Total';
    }

    quoteBody.items = extraFields.map((g) => {
      console.log(g);
      const mappedFields = g.map((f, i) => {
        const obj = {};
        f.forEach(_f => {
          if (_f.remote && _f.dataType !== 'numeric') {
            const selectedOption = _f.options.find(opt => opt._id === _f.value);
            obj[_f.name] = selectedOption !== undefined ? selectedOption.name : _f.value;
          } else {
            obj[_f.name] = _f.name === 'vehicleType' ? _f.value : getFormattedValue(_f);
          }
          obj.groupName = _f.groupName;
        });

        obj.qItem = extraFields.reduce((prev, curr) => prev += curr.length, 0);

        if (obj.vehicleType) {
          obj.vehicleType = 'HeavyTruck';
        } else {
          obj.vehicleType = 'LightTruck';
        }

        return obj;
      });

      return mappedFields;
    }).reduce((prev, curr) => prev = prev.concat(...curr), [])

    const leadField = overview.find(step => step.isLead);

    quoteBody.lead = leadField ? leadField.value : null;

    quoteBody.calculationType = data.riskType.toUpperCase() === 'AUTOS' && !data.description.toLowerCase().includes('demo') ? 'averageRatePremium' : 'directRatePremium';

    return {
      lead: leadField !== undefined ? { email: leadField.value } : null,
      item: quoteBody
    };
  };

  const saveQuotation = () => {
    const _data = data;
    const overview = getOverview();
    _data.groups = data.groups.map(group => {
      const _group = group;
      if (!_group.multiple) {
        _group.fields.map(field => {
          const _field = field;

          _field.value = overview.filter(step => step.type !== 'multiple').find(step => step._id === _field._id).value;

          return _field;
        });
      }
      return _group;
    });

    _data.extraFields = extraFields;
    sessionStorage.setItem(`quotation::${riskType}`, JSON.stringify(_data));
  };

  const proceed = async () => {
    console.log('Proceed');
    setLoading(true);
    saveQuotation();
    const quoteBody = buildQuoteBody();
    const resultQuote = await dispatch(postSaveQuotation(quoteBody, riskType));
    sessionStorage.setItem(`quotation::quote::${riskType}`, JSON.stringify({ plan: {}, quotation: resultQuote }));
    localStorage.setItem('quotation::id', resultQuote._id);
    setLoading(false);
    if (isEmbed) {
      navigate(`/embed/quote/${riskType.toLowerCase()}/${data._id}/${insurance}/${formBuilderId}/${resultQuote._id}/proposal`);
    } else {
      navigate(`/quote/${riskType.toLowerCase()}/${data._id}/${insurance}/${formBuilderId}/${resultQuote._id}/proposal`);
    }
  }

  const reset = () => {
    const resettedExtraFields = extraFields.map(ef => []);
    steps.map(group => {
      const _group = group;

      _group.fields.map(f => {
        const _f = f;

        _f.value = null;
        // _f.completed = false;

        return _f;
      });

      return _group;
    });
    setExtraFields(resettedExtraFields);
    setActiveStep(0);
    setIndex(0);
    setInputValue('');
  }

  const checkForValue = () => {
    return steps.every(group => {
      return group.fields.every(f => f.value === null)
    });
  };

  const getTemplate = (str) => {
    const template = Handlebars.compile(str);
    return template();
  };

  const currentGroup = extraFields[extraFieldsGroupIndex];

  return (
    <Grid container justify="center" className={classes.appContainer}>
      <Grid item xs={12} sm={9} md={7} className={`cardAppContainer ${isEmbed ? 'embed' : ''}`}>
        <Hidden xsDown>
          <Box sx={{ width: '100%' }}>
            {activeStep < steps.length && <>
              {data.options.navigationType !== 'wizard_arrow' ? <Stepper activeStep={activeStep}>
                {steps.map((group, i) => (
                  <Step key={group.groupName} style={{ flex: `calc(100% / ${steps.length})` }}>
                    <StepLabel>{group.groupLabel}</StepLabel>
                  </Step>
                ))}
              </Stepper> : <CustomWizard activeStep={activeStep} steps={steps.map(s => s.groupLabel)} style={{ flex: `calc(100% / ${steps.length})` }} />}
            </>}
            <div>
              {activeStep === steps.length ? (
                <>
                  <div className={classes.label}>
                    <ArrowForwardIos color="primary" />
                    <InputLabel id="external-input-label">Revisa que todos los datos sean correctos</InputLabel>
                  </div>
                  <TagsNavigator
                    classes={classes}
                    index={index}
                    groups={steps}
                    fillingExtraFields={fillingExtraFields}
                    extraFields={extraFields}
                    currentGroupIndex={extraFieldsGroupIndex}
                    wizard
                    readOnly
                    isLast={activeStep === steps.length}
                    quotationScreenOptions={data.options} />
                  <Grid container style={{ justifyContent: 'center', marginTop: 40 }} className="contentForm">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={goToPrevStep}
                      style={{ marginRight: 20 }}
                    >
                      <ArrowBackIos />
                      Volver
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleShowWarningMessage}
                      style={{ marginRight: 20 }}
                    >
                      Borrar
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={proceed}
                      disabled={!isValid()}
                    >
                      COTIZAR
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <WizardTagsNavigator
                    group={steps[activeStep]}
                    handleCloseTag={handleCloseTag}
                    handleFillExtraFields={handleFillExtraFields}
                    fillingExtraFields={fillingExtraFields}
                    extraFields={extraFields}
                    handleDeleteExtraField={handleDeleteExtraField}
                    handleDeleteExtraFieldTag={handleDeleteExtraFieldTag}
                  />
                  {/* {(steps[activeStep].multiple && !fillingExtraFields) && (
                    <h2 className="titleSection">Agregar <IconButton color="primary" onClick={handleFillExtraFields}><Add /></IconButton></h2>
                  )} */}
                  {!steps[activeStep].multiple && <>
                    {!fillingExtraFields && (<QuoteContainer
                      handleSelect={handleSelect}
                      handleCheck={handleCheck}
                      name={steps[activeStep].fields[index].name}
                      label={steps[activeStep].fields[index].label}
                      dataType={steps[activeStep].fields[index].dataType}
                      controlType={steps[activeStep].fields[index].controlType}
                      options={steps[activeStep].fields[index].options}
                      data={dynamicReducer.data}
                      value={inputValue}
                      validations={steps[activeStep].fields[index].validations}
                      isLast={!isLast()}
                      wizard
                      isOptional={steps[activeStep].fields[index].isOptional}
                      mask={steps[activeStep].fields[index].mask}
                      // completed={steps[activeStep].fields[index].completed}
                      isSearchable={steps[activeStep].fields[index].isSearchable}
                      isGrid={steps[activeStep].fields[index].isGrid}
                      quotationScreenOptions={data.options}
                      handlePreSelect={handlePreSelect}
                      preSelectedValue={preSelectedValue}
                      handleNextButtonValid={handleNextButtonValid}
                    />)}

                  </>}

                  {fillingExtraFields && (
                    <>
                      {/* <div className={classes.itemBack}>
                        <IconButton color="primary" onClick={handleFillExtraFields}><ArrowBackIos /></IconButton>
                        <h2 className="titleSection"></h2>
                      </div> */}
                      <QuoteContainer
                        handleSelect={(value) => handleSelect(value, true)}
                        handleCheck={(value) => handleCheck(value, true)}
                        name={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].name}
                        label={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].label}
                        dataType={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].dataType}
                        controlType={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].controlType}
                        options={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].options}
                        data={dynamicReducer.data}
                        value={inputValue}
                        validations={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].validations}
                        isOptional={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].isOptional}
                        mask={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].mask}
                        isSearchable={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].isSearchable}
                        isGrid={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].isGrid}
                        quotationScreenOptions={data.options}
                        handlePreSelect={handlePreSelect}
                        preSelectedValue={preSelectedValue}
                        handleNextButtonValid={handleNextButtonValid}
                      />

                      <Grid container style={{ justifyContent: 'space-between', margin: '20px 16px 0 16px', width: 'auto' }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleFillExtraFields}
                        >
                          <ArrowBackIos />
                          Volver
                        </Button>
                        {data.options.nextButtonType === 'ext'
                          && <Button
                            variant="contained"
                            color="primary"
                            disabled={!isNextButtonValid}
                            onClick={handleSetOnNext}
                          >
                            Siguiente
                            <ArrowForwardIos style={{ fontSize: 18 }} />
                          </Button>}
                      </Grid>
                    </>
                  )}

                  {!fillingExtraFields && <Grid container justify="space-between" style={{ marginTop: 40 }}>
                    <div>
                      {activeStep > 0 && <Button
                        variant="contained"
                        color="secondary"
                        onClick={goToPrevStep}
                      >
                        <ArrowBackIos style={{ fontSize: 18 }} />
                        Volver
                      </Button>}
                      {!checkForValue() && <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleShowWarningMessage}
                        style={{ marginLeft: 20 }}
                      >
                        Borrar
                      </Button>}
                    </div>
                    {(!steps[activeStep].multiple
                      // || steps[activeStep].fields[index].completed)
                      && data.options.nextButtonType === 'ext'
                    )
                      && <Button
                        variant="contained"
                        color="primary"
                        disabled={!isNextButtonValid}
                        onClick={handleSetOnNext}
                        style={{ marginRight: 16 }}
                      >
                        Siguiente
                        <ArrowForwardIos style={{ fontSize: 18 }} />
                      </Button>}
                    {((steps[activeStep].multiple && currentGroup.length >= steps[activeStep].min)
                      // || steps[activeStep].fields[index].completed)
                    )
                      && <Button
                        variant="contained"
                        color="primary"
                        onClick={goToNextStep}
                      >
                        Siguiente
                        <ArrowForwardIos style={{ fontSize: 18 }} />
                      </Button>}
                  </Grid>}
                </>
              )}
            </div>
          </Box>
        </Hidden>
        <Hidden smUp>
          <Box sx={{ width: '100%' }}>
            <StyledMobileStepper
              variant="dots"
              steps={steps.length + 1}
              position="static"
              activeStep={activeStep}
            />
            <div className={classes.mobileTitle}>
              <Typography>{steps[activeStep] ? steps[activeStep].groupLabel : 'Resumen'}</Typography>
            </div>
            <div>
              {activeStep === steps.length ? (
                <>
                  <TagsNavigator
                    classes={classes}
                    index={index}
                    groups={steps}
                    fillingExtraFields={fillingExtraFields}
                    extraFields={extraFields}
                    currentGroupIndex={extraFieldsGroupIndex}
                    wizard
                    readOnly />
                  <Grid container style={{ justifyContent: 'center', marginTop: 40 }} className="contentForm mobileButtonRow">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={goToPrevStep}
                      style={{ marginRight: 20 }}
                    >
                      <ArrowBackIos />
                      <span>Volver</span>
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleShowWarningMessage}
                      style={{ marginRight: 20 }}
                    >
                      <span>Borrar</span>
                      <span className="show"><RemoveCircleOutline style={{ fontSize: 18 }} /></span>
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={proceed}
                      disabled={!isValid()}
                    >
                      COTIZAR
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <WizardTagsNavigator
                    group={steps[activeStep]}
                    handleCloseTag={handleCloseTag}
                    handleFillExtraFields={handleFillExtraFields}
                    fillingExtraFields={fillingExtraFields}
                    extraFields={extraFields}
                    handleDeleteExtraField={handleDeleteExtraField}
                  />
                  {/* {(steps[activeStep].multiple && !fillingExtraFields) && (
                    <h2 className="titleSection">Agregar <IconButton color="primary" onClick={handleFillExtraFields}><Add /></IconButton></h2>
                  )} */}
                  {!steps[activeStep].multiple && <>
                    {!fillingExtraFields && (<QuoteContainer
                      handleSelect={handleSelect}
                      handleCheck={handleCheck}
                      name={steps[activeStep].fields[index].name}
                      label={steps[activeStep].fields[index].label}
                      dataType={steps[activeStep].fields[index].dataType}
                      controlType={steps[activeStep].fields[index].controlType}
                      options={steps[activeStep].fields[index].options}
                      data={dynamicReducer.data}
                      value={inputValue}
                      validations={steps[activeStep].fields[index].validations}
                      isLast={!isLast()}
                      wizard
                      isOptional={steps[activeStep].fields[index].isOptional}
                      mask={steps[activeStep].fields[index].mask}
                      // completed={steps[activeStep].fields[index].completed}
                      isSearchable={steps[activeStep].fields[index].isSearchable}
                      isGrid={steps[activeStep].fields[index].isGrid}
                      quotationScreenOptions={data.options}
                      handlePreSelect={handlePreSelect}
                      preSelectedValue={preSelectedValue}
                      handleNextButtonValid={handleNextButtonValid}
                    />)}

                  </>}

                  {fillingExtraFields && (
                    <>
                      <div className={classes.itemBack}>
                        <IconButton color="primary" onClick={handleFillExtraFields}><ArrowBackIos /></IconButton>
                        <h2 className="titleSection"></h2>
                      </div>
                      <QuoteContainer
                        handleSelect={(value) => handleSelect(value, true)}
                        handleCheck={(value) => handleCheck(value, true)}
                        name={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].name}
                        label={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].label}
                        dataType={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].dataType}
                        controlType={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].controlType}
                        options={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].options}
                        data={dynamicReducer.data}
                        value={inputValue}
                        validations={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].validations}
                        isOptional={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].isOptional}
                        mask={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].mask}
                        isSearchable={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].isSearchable}
                        isGrid={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].isGrid}
                        quotationScreenOptions={data.options}
                        handlePreSelect={handlePreSelect}
                        preSelectedValue={preSelectedValue}
                        handleNextButtonValid={handleNextButtonValid}
                      />
                      {/* <Grid container style={{ justifyContent: 'flex-end' }} className="contentForm" style={{ marginTop: 20 }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleFillExtraFields}
                        >
                          <Cancel />
                          Cancelar
                        </Button>
                      </Grid> */}
                    </>
                  )}

                  {!fillingExtraFields && <Grid container justify="space-between" style={{ marginTop: 40 }}>
                    {activeStep > 0 && <div className="mobileButtonRow">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={goToPrevStep}
                      >
                        <ArrowBackIos style={{ fontSize: 18 }} />
                        <span>Volver</span>
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleShowWarningMessage}
                        style={{ marginLeft: 20 }}
                      >
                        <span>Borrar</span>
                        <span className="show"><RemoveCircleOutline style={{ fontSize: 18 }} /></span>
                      </Button>
                    </div>}
                    <div className="mobileButtonRow">
                      {((steps[activeStep].multiple && currentGroup.length >= steps[activeStep].min)
                        // || steps[activeStep].fields[index].completed)
                      )
                        && <Button
                          variant="contained"
                          color="primary"
                          onClick={goToNextStep}
                        >
                          <span>Siguiente</span>
                          <ArrowForwardIos style={{ fontSize: 18 }} />
                        </Button>}
                    </div>
                  </Grid>}
                </>
              )}
            </div>
          </Box>
        </Hidden>
      </Grid>
      {loading
        ? <div className={classes.loader}>
          <CircularProgress color="primary" />
        </div> : ''}
      <Dialog onClose={handleCloseWarningMessage} open={showWarningMessage} classes={{ paper: classes.modal }} maxWidth="xs">
        {data.options.removeMessage ? <DialogContent dangerouslySetInnerHTML={{ __html: getTemplate(data.options.removeMessage) }} /> : <DialogContent>
          <DialogContentText className={classes.modalMessage}>¿Esta seguro que desea reiniciar su cotización?</DialogContentText>
        </DialogContent>}
        <StyledDialogActions>
          <Button onClick={handleAcceptWarningMessage} color="primary">Borrar</Button>
          <Button onClick={handleCloseWarningMessage} color="secondary">Cancelar</Button>
        </StyledDialogActions>
      </Dialog>
    </Grid>
  );
};

export default Wizard;