import 'date-fns';
import React, { useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { es } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Grid, InputAdornment, IconButton, Tooltip, InputLabel } from '@material-ui/core';
import { ArrowForwardIos, Input } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  field: {
    "& > div": {
      flex: 1
    }
  },
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: 16,
    marginBottom: 18,
    '& svg': {
      marginRight: 6,
      fontSize: 16
    },
    color: theme.palette.typography.main,
    '& label': {
      overflow: 'unset',
      maxWidth: '100%',
      whiteSpace: 'break-spaces'
    }
  }
}));

const QuoteInputDate = ({
  label,
  name,
  handleCheck,
  handleSelect,
  isLast,
  value,
  isOptional,
  min,
  max,
  controlType,
  quotationScreenOptions,
  handleNextButtonValid
}) => {
  const classes = useStyles();
  const config = useSelector(state => state.config.data);

  const maxDateByAge = max !== null ? moment().set(min === 0 ? {} : { date: 31, month: 11 }).subtract(min, 'y').toDate() : undefined;
  const minDateByAge = min !== null ? moment().set({ date: 1, month: 0 }).subtract(max, 'y').toDate() : undefined;

  useEffect(() => {
    if (quotationScreenOptions.nextButtonType === 'ext') {
      handleNextButtonValid(isValid());
    }
  }, [value]);

  const isValidObjDate = (d) => {
    return d instanceof Date && !isNaN(d);
  }

  const isValidRangeDate = (thisDate) => {
    if (!isValidObjDate(thisDate)) return false;

    const minRangeDate = minDateByAge || new Date('1900-01-01');
    const maxRangeDate = maxDateByAge || new Date('2100-01-01');
    const currentDate = new Date(thisDate);

    return minRangeDate <= currentDate && currentDate <= maxRangeDate;
  }

  const isValid = () => {
    if (isOptional) {
      return true;
    }

    const dateValue = new Date(value);
    return value && value !== '' && isValidRangeDate(dateValue);
  };

  const handleOnChange = (value) => {
    handleSelect(value);
  }

  const handleOnCheck = () => {
    handleCheck(value);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
      {(config.theme && !config.theme.labelPosition) && (
        <div className={classes.label}>
          <ArrowForwardIos color="primary" />
          <InputLabel id="external-input-label">{label}</InputLabel>
        </div>)}
      <Grid container justify="space-around" className={classes.field}>
        <KeyboardDatePicker
          autoOk
          openTo="year"
          variant="inline"
          format="dd/MM/yyyy"
          id="date-picker-inline"
          label={(config.theme && config.theme.labelPosition) ? label : null}
          disableFuture
          disableToolbar
          value={value !== '' ? value : null}
          invalidDateMessage="Formato de fecha invalida"
          onChange={handleOnChange}
          KeyboardButtonProps={{
            'aria-label': 'cambiar fecha',
          }}
          minDateMessage={<span>{max !== null ? `La edad maxima es ${max}` : 'La fecha no deberia ser menor al minimo'}</span>}
          maxDateMessage={<span>{min !== null ? `La edad minima es ${min}` : 'La fecha no deberia ser mayor al maximo'}</span>}
          maxDate={maxDateByAge}
          minDate={minDateByAge}
        />
        {quotationScreenOptions.nextButtonType === 'int' && <Tooltip title={isLast ? 'Ingresar' : 'Siguiente'} arrow open={isValid()} placement="top">
          <IconButton
            aria-label="check input value"
            onClick={handleOnCheck}
            disabled={!isValid()}
            style={{
              color: isValid() ? '#4C1DB7' : 'rgba(0, 0, 0, 0.26)',
              padding: '12px 12px 12px 12px',
              alignItems: 'baseline'
            }}
          >
            <Input />
          </IconButton>
        </Tooltip>}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default QuoteInputDate;
