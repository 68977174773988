import axios from 'axios';
import { createAsyncAction } from 'redux-promise-middleware-actions';
import settings from '../settings';
import { validateResponse } from '../utils';

export const GET_WITH_REMOTE = 'GET_WITH_REMOTE';

export const getWithRemote = createAsyncAction(
    GET_WITH_REMOTE,
    async (remote) => {
        const headers = { 'Content-Type': 'application/json' };
        const res = await fetch(`${settings.quotationUrlApi}${remote}`, { method: 'GET', headers })
            .then((response) => validateResponse(response));
        
        return res;
    }
);

export const getWithRemoteApi = async (remote) => {
    const headers = { 'Content-Type': 'application/json' };
    const res = await fetch(`${settings.quotationUrlApi}${remote}`, { method: 'GET', headers })
        .then((response) => validateResponse(response));
    
    return res;
};
