export const GET_YEARS_PENDING = "GET_YEARS_PENDING";
export const GET_YEARS_FULFILLED = "GET_YEARS_FULFILLED";
export const GET_YEARS_REJECTED = "GET_YEARS_REJECTED";
export const PATCH_YEARS_ERROR = "PATCH_YEARS_ERROR";

export const GET_BRANDS_PENDING = "GET_BRANDS_PENDING";
export const GET_BRANDS_FULFILLED = "GET_BRANDS_FULFILLED";
export const GET_BRANDS_REJECTED = "GET_BRANDS_REJECTED";
export const PATCH_BRANDS_ERROR = "PATCH_BRANDS_ERROR";

export const GET_MODELS_PENDING = "GET_MODELS_PENDING";
export const GET_MODELS_FULFILLED = "GET_MODELS_FULFILLED";
export const GET_MODELS_REJECTED = "GET_MODELS_REJECTED";
export const PATCH_MODELS_ERROR = "PATCH_MODELS_ERROR";

export const GET_VERSIONS_PENDING = "GET_VERSIONS_PENDING";
export const GET_VERSIONS_FULFILLED = "GET_VERSIONS_FULFILLED";
export const GET_VERSIONS_REJECTED = "GET_VERSIONS_REJECTED";
export const PATCH_VERSIONS_ERROR = "PATCH_VERSIONS_ERROR";

export const GET_ACCESORIES_PENDING = "GET_ACCESORIES_PENDING";
export const GET_ACCESORIES_FULFILLED = "GET_ACCESORIES_FULFILLED";
export const GET_ACCESORIES_REJECTED = "GET_ACCESORIES_REJECTED";
export const PATCH_ACCESORIES_ERROR = "PATCH_ACCESORIES_ERROR";

export const UPDATE_QUOTATION_SYNC = "UPDATE_QUOTATION_SYNC";
export const POST_SAVE_QUOTATION_PENDING = "POST_SAVE_QUOTATION_PENDING";
export const POST_SAVE_QUOTATION_FULFILLED = "POST_SAVE_QUOTATION_FULFILLED";
export const POST_SAVE_QUOTATION_REJECTED = "POST_SAVE_QUOTATION_REJECTED";

export const POST_UPDATE_QUOTATION_PENDING = "POST_UPDATE_QUOTATION_PENDING";
export const POST_UPDATE_QUOTATION_FULFILLED =
    "POST_UPDATE_QUOTATION_FULFILLED";
export const POST_UPDATE_QUOTATION_REJECTED = "POST_UPDATE_QUOTATION_REJECTED";

export const POST_UPDATE_QUOTATION_SCREEN_VALUES =
    "POST_UPDATE_QUOTATION_SCREEN_VALUES";
export const POST_UPDATE_QUOTATION_SCREEN_VALUES_PENDING =
    "POST_UPDATE_QUOTATION_SCREEN_VALUES_PENDING";
export const POST_UPDATE_QUOTATION_SCREEN_VALUES_FULFILLED =
    "POST_UPDATE_QUOTATION_SCREEN_VALUES_FULFILLED";
export const POST_UPDATE_QUOTATION_SCREEN_VALUES_REJECTED =
    "POST_UPDATE_QUOTATION_SCREEN_VALUES_REJECTED";
export const RESET_QUOTATION = "RESET_QUOTATION";

export const ADD_TAG_CAR = "ADD_TAG_CAR";
export const ADD_TAG_CONTACT = "ADD_TAG_CONTACT";
export const ADD_TAG_ACC = "ADD_TAG_ACC";
export const REMOVE_TAG_CAR = "REMOVE_TAG_CAR";
export const REMOVE_TAG_CONTACT = "REMOVE_TAG_CONTACT";
export const REMOVE_TAG_ACC = "REMOVE_TAG_ACC";
export const PATCH_DATA_LIST = "PATCH_DATA_LIST";
export const PATCH_PLAN = "PATCH_PLAN";
export const PATCH_MAIL = "PATCH_MAIL";
export const REMOVE_PLAN_DETAIL = "REMOVE_PLAN_DETAIL";
export const PATCH_QUOTATION_ERROR = "PATCH_QUOTATION_ERROR";

export const REMOVE_ALL_QUOTE = "REMOVE_ALL_QUOTE";

export const SUCCESS_QUOTE = "SUCCESS_QUOTE";

export const POST_LEAD_PENDING = "POST_LEAD_PENDING";
export const POST_LEAD_FULFILLED = "POST_LEAD_FULFILLED";
export const POST_LEAD_REJECTED = "POST_LEAD_REJECTED";

export const UPDATE_LEAD_PENDING = "UPDATE_LEAD_PENDING";
export const UPDATE_LEAD_FULFILLED = "UPDATE_LEAD_FULFILLED";
export const UPDATE_LEAD_REJECTED = "UPDATE_LEAD_REJECTED";
export const CLEAN_LEAD = "CLEAN_LEAD";
export const ADD_TRACKING_LEAD = "ADD_TRACKING_LEAD";
export const GET_LEAD_BY_QUOTATION = "GET_LEAD_BY_QUOTATION";
export const GET_LEAD_AND_QUOTATION_PENDING = "GET_LEAD_AND_QUOTATION_PENDING";
export const GET_LEAD_AND_QUOTATION_FULFILLED =
    "GET_LEAD_AND_QUOTATION_FULFILLED";
export const GET_LEAD_AND_QUOTATION_REJECTED =
    "GET_LEAD_AND_QUOTATION_REJECTED";

export const GET_INSURANCE_PENDING = "GET_INSURANCE_PENDING";
export const GET_INSURANCE_FULFILLED = "GET_INSURANCE_FULFILLED";
export const GET_INSURANCE_REJECTED = "GET_INSURANCE_REJECTED";

export const GET_INSURANCE_ID = "GET_INSURANCE_ID";
export const GET_INSURANCE_ID_PENDING = "GET_INSURANCE_ID_PENDING";
export const GET_INSURANCE_ID_FULFILLED = "GET_INSURANCE_ID_FULFILLED";
export const GET_INSURANCE_ID_REJECTED = "GET_INSURANCE_ID_REJECTED";

export const RESET_INSURANCE = "RESET_INSURANCE";

export const GET_TEXT_COVERAGE = "GET_TEXT_COVERAGE";
export const GET_TEXT_COVERAGE_PENDING = "GET_TEXT_COVERAGE_PENDING";
export const GET_TEXT_COVERAGE_FULFILLED = "GET_TEXT_COVERAGE_FULFILLED";
export const GET_TEXT_COVERAGE_REJECTED = "GET_TEXT_COVERAGE_REJECTED";

export const ADD_TEXT_COVERAGE = "ADD_TEXT_COVERAGE";
export const ADD_TEXT_COVERAGE_PENDING = "ADD_TEXT_COVERAGE_PENDING";
export const ADD_TEXT_COVERAGE_FULFILLED = "ADD_TEXT_COVERAGE_FULFILLED";
export const ADD_TEXT_COVERAGE_REJECTED = "ADD_TEXT_COVERAGE_REJECTED";

export const UPDATE_TEXT_COVERAGE = "UPDATE_TEXT_COVERAGE";
export const UPDATE_TEXT_COVERAGE_PENDING = "UPDATE_TEXT_COVERAGE_PENDING";
export const UPDATE_TEXT_COVERAGE_FULFILLED = "UPDATE_TEXT_COVERAGE_FULFILLED";
export const UPDATE_TEXT_COVERAGE_REJECTED = "UPDATE_TEXT_COVERAGE_REJECTED";

export const DELETE_TEXT_COVERAGE = "DELETE_TEXT_COVERAGE";
export const DELETE_TEXT_COVERAGE_PENDING = "DELETE_TEXT_COVERAGE_PENDING";
export const DELETE_TEXT_COVERAGE_FULFILLED = "DELETE_TEXT_COVERAGE_FULFILLED";
export const DELETE_TEXT_COVERAGE_REJECTED = "DELETE_TEXT_COVERAGE_REJECTED";

export const GET_NEWS = "GET_NEWS";
export const GET_NEWS_PENDING = "GET_NEWS_PENDING";
export const GET_NEWS_FULFILLED = "GET_NEWS_FULFILLED";
export const GET_NEWS_REJECTED = "GET_NEWS_REJECTED";

export const GET_NEWS_BY_SLUG = "GET_NEWS_BY_SLUG";
export const GET_NEWS_BY_SLUG_PENDING = "GET_NEWS_BY_SLUG_PENDING";
export const GET_NEWS_BY_SLUG_FULFILLED = "GET_NEWS_BY_SLUG_FULFILLED";
export const GET_NEWS_BY_SLUG_REJECTED = "GET_NEWS_BY_SLUG_REJECTED";

export const FILTER_NEWS = "FILTER_NEWS";
export const RESET_NEWS = "RESET_NEWS";

export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE";

export const HIDE_ERROR_MESSAGE = "HIDE_ERROR_MESSAGE";

export const SHOW_MESSAGE = "SHOW_MESSAGE";

export const HIDE_MESSAGE = "HIDE_MESSAGE";

export const GET_CLIENT_INFO = "GET_CLIENT_INFO";
export const GET_CLIENT_INFO_PENDING = "GET_CLIENT_INFO_PENDING";
export const GET_CLIENT_INFO_FULFILLED = "GET_CLIENT_INFO_FULFILLED";
export const GET_CLIENT_INFO_REJECTED = "GET_CLIENT_INFO_REJECTED";

export const RESET_CLIENT_INFO = "RESET_CLIENT_INFO";

export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
export const GET_PAYMENT_METHODS_PENDING = "GET_PAYMENT_METHODS_PENDING";
export const GET_PAYMENT_METHODS_FULFILLED = "GET_PAYMENT_METHODS_FULFILLED";
export const GET_PAYMENT_METHODS_REJECTED = "GET_PAYMENT_METHODS_REJECTED";

export const FILTER_PAYMENT_METHODS = "FILTER_PAYMENT_METHODS";
export const RESET_PAYMENT_METHODS = "RESET_PAYMENT_METHODS";

export const GET_PAYMENT_STATUS = "GET_PAYMENT_STATUS"
export const GET_PAYMENT_STATUS_PENDING = "GET_PAYMENT_STATUS_PENDING"
export const GET_PAYMENT_STATUS_FULFILLED = "GET_PAYMENT_STATUS_FULFILLED"
export const GET_PAYMENT_STATUS_REJECTED = "GET_PAYMENT_STATUS_REJECTED"
export const RESET_PAYMENT_STATUS = "RESET_PAYMENT_STATUS"

export const SAVE_CLIENT_RECOGNITION_INFO = "SAVE_CLIENT_RECOGNITION_INFO";

export const RECOVER_QUOTATION = "RECOVER_QUOTATION";

export const GET_QUOTATION_BY_ID = "GET_QUOTATION_BY_ID";
export const GET_QUOTATION_BY_ID_PENDING = "GET_QUOTATION_BY_ID_PENDING";
export const GET_QUOTATION_BY_ID_FULFILLED = "GET_QUOTATION_BY_ID_FULFILLED";
export const GET_QUOTATION_BY_ID_REJECTED = "GET_QUOTATION_BY_ID_REJECTED";

export const GET_USER_CONFIG = "GET_USER_CONFIG";
export const GET_USER_CONFIG_PENDING = "GET_USER_CONFIG_PENDING";
export const GET_USER_CONFIG_FULFILLED = "GET_USER_CONFIG_FULFILLED";
export const GET_USER_CONFIG_REJECTED = "GET_USER_CONFIG_REJECTED";

export const CREATE_DIRECT_EMISSION = "CREATE_DIRECT_EMISSION";
export const CREATE_DIRECT_EMISSION_PENDING = "CREATE_DIRECT_EMISSION_PENDING";
export const CREATE_DIRECT_EMISSION_FULFILLED =
    "CREATE_DIRECT_EMISSION_FULFILLED";
export const CREATE_DIRECT_EMISSION_REJECTED =
    "CREATE_DIRECT_EMISSION_REJECTED";

export const CREATE_REQUEST_EMISSION = "CREATE_REQUEST_EMISSION";
export const CREATE_REQUEST_EMISSION_PENDING =
    "CREATE_REQUEST_EMISSION_PENDING";
export const CREATE_REQUEST_EMISSION_FULFILLED =
    "CREATE_REQUEST_EMISSION_FULFILLED";
export const CREATE_REQUEST_EMISSION_REJECTED =
    "CREATE_DIRECT_EMISSION_REJECTED";

export const GET_LAYOUT_ID_PENDING = "GET_LAYOUT_ID_PENDING";
export const GET_LAYOUT_ID_FULFILLED = "GET_LAYOUT_ID_FULFILLED";
export const GET_LAYOUT_ID_REJECTED = "GET_LAYOUT_ID_REJECTED";

export const GET_DECISION_TABLES_PENDING = "GET_DECISION_TABLES_PENDING";
export const GET_DECISION_TABLES_FULFILLED = "GET_DECISION_TABLES_FULFILLED";
export const GET_DECISION_TABLES_REJECTED = "GET_DECISION_TABLES_REJECTED";

