import React from 'react';

import QuoteListNumeric from './commons/quoteListNumeric';
import QuoteListString from './commons/quoteListString';
import QuoteListBoolean from './commons/quoteListBoolean';
import QuoteInputString from './commons/quoteInputString';
import QuoteInputDate from './commons/quoteInputDate';
import QuoteListObject from './commons/quoteListObject';

const QuoteContainer = function ({
    dataType,
    controlType,
    label,
    name,
    options,
    data,
    handleSelect,
    handleCheck,
    isLast,
    value,
    validations,
    wizard,
    isOptional,
    mask,
    // completed,
    isSearchable,
    isGrid,
    quotationScreenOptions,
    handlePreSelect,
    preSelectedValue,
    handleNextButtonValid
}) {
    console.log('OPT', options);
    // console.log('IS Completed?', completed);
    const formatByMask = (v) => {
        if (mask) {
            switch(mask.toLowerCase()) {
                case 'mayuscula':
                    return v.toUpperCase();
                case 'moneda':
                    return new Intl.NumberFormat('en-US', { maximumFractionDigits: 3 }).format(v);
                default:
                    return v;
            };
        } else {
            return v;
        }
    };
    const switchDataTypesComponents = () => {
        switch (dataType) {
            case 'numeric':
                return (
                    <QuoteListNumeric
                        handleSelect={handleSelect}
                        data={options}
                        label={label}
                        isOptional={isOptional}
                        isSearchable={isSearchable}
                        isGrid={isGrid}
                        quotationScreenOptions={quotationScreenOptions}
                        handlePreSelect={handlePreSelect}
                        preSelectedValue={preSelectedValue}
                        handleNextButtonValid={handleNextButtonValid}
                    />
                );
            case 'string':
                return (
                    <QuoteListString
                        data={(options.length > 0) ? options : data}
                        handleSelect={handleSelect}
                        label={label}
                        isOptional={isOptional}
                        isSearchable={isSearchable}
                        quotationScreenOptions={quotationScreenOptions}
                        handlePreSelect={handlePreSelect}
                        preSelectedValue={preSelectedValue}
                        handleNextButtonValid={handleNextButtonValid}
                    />
                );
            case 'bool':
                return (
                    <QuoteListBoolean
                        data={options}
                        name={name}
                        label={label}
                        handleSelect={handleSelect}
                        isOptional={isOptional}
                        isSearchable={isSearchable}
                        quotationScreenOptions={quotationScreenOptions}
                        handlePreSelect={handlePreSelect}
                        preSelectedValue={preSelectedValue}
                        controlType={controlType}
                        handleNextButtonValid={handleNextButtonValid}
                    />
                );
            case 'object':
                return (
                    <QuoteListObject
                        data={(options.length > 0) ? options : data}
                        handleSelect={handleSelect}
                        label={label}
                        isOptional={isOptional}
                        isSearchable={isSearchable}
                        quotationScreenOptions={quotationScreenOptions}
                        handlePreSelect={handlePreSelect}
                        preSelectedValue={preSelectedValue}
                        handleNextButtonValid={handleNextButtonValid}
                    />
                )
            default:
                break;
        }
    };

    // if (!completed) {
        switch (controlType) {
            case 'chip':
            case 'simple':
            case 'custom':
                return switchDataTypesComponents();
            case 'input text':
            case 'input email':
                return (
                    <QuoteInputString
                        handleSelect={handleSelect}
                        label={label}
                        name={name}
                        type={dataType === 'string' ? controlType.split(' ')[1] : dataType}
                        handleCheck={handleCheck}
                        isLast={isLast}
                        value={value}
                        min={options.length > 0 ? options[0] : null}
                        max={options.length > 0 ? options[1] : null}
                        validations={validations}
                        wizard={wizard}
                        isOptional={isOptional}
                        mask={mask}
                        formatByMask={formatByMask}
                        quotationScreenOptions={quotationScreenOptions}
                        handleNextButtonValid={handleNextButtonValid}
                    />
                );
            case 'input autocomplete':
                return (
                    <QuoteInputString
                        handleSelect={handleSelect}
                        label={label}
                        name={name}
                        type={controlType.split(' ')[1]}
                        handleCheck={handleCheck}
                        isLast={isLast}
                        value={value}
                        autocomplete
                        isOptional={isOptional}
                        mask={mask}
                        formatByMask={formatByMask}
                        quotationScreenOptions={quotationScreenOptions}
                        handleNextButtonValid={handleNextButtonValid}
                    />
                );
            case 'input date':
            case 'input birthdate':
                return (
                    <QuoteInputDate
                        handleSelect={handleSelect}
                        label={label}
                        name={name}
                        isLast={isLast}
                        value={value}
                        handleCheck={handleCheck}
                        isOptional={isOptional}
                        min={options.length > 0 ? options[0] : null}
                        max={options.length > 0 ? options[1] : null}
                        mask={mask}
                        formatByMask={formatByMask}
                        quotationScreenOptions={quotationScreenOptions}
                        handleNextButtonValid={handleNextButtonValid}
                    />
                );
            default:
                break;
        }
    // } else return null;
};

export default QuoteContainer;
