import React from 'react';
import { navigate, useLocation } from '@reach/router';
import { Grid } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        boxShadow: `0px 0px 0px rgba(0, 0, 0, 0.2), 
                0px 3px 1px rgba(0, 0, 0, 0.12), 
                0px 1px 1px rgba(0, 0, 0, 0.14)`,
        marginBottom: props => props.title === 'Inicio' ? 0 : 32,
        zIndex: 1,
        paddingLeft: 22,
        [theme.breakpoints.down('xs')]: {
            minHeight: 14
        },
        background: '#FFFFFF'
    },
    title: {
        color: '#4B4B4B',
        fontSize: 26
    }
}));

const onClick = (to, click, location, riskType, availableRecognition) => {
    const goBack = {
        proposal: () => {
            const fragmentUrl = location.pathname.split('/proposal')[0].split('/');
            const removeResultQuoteIdParameter = fragmentUrl.pop();
            const urlInfo = `${fragmentUrl.join('/')}/info`;
            navigate(urlInfo);
        },
        recognition: () => {
            const fragmentUrl = location.pathname.split('/recognition')[0].split('/');
            const urlInfo = `${fragmentUrl.join('/')}/proposal`;
            navigate(urlInfo);
        },
        info: () => navigate('/'),
        clientInfo: () => {
            const fragmentUrl = location.pathname.split('/client-info')[0].split('/');
            let urlInfo = `${fragmentUrl.join('/')}/recognition`;

            if (riskType === 'AUTOS' || !availableRecognition) {
                urlInfo = `${fragmentUrl.join('/')}/proposal`;
            }

            navigate(urlInfo);
        },
        inspection: () => {
            const fragmentUrl = location.pathname.split('/inspection')[0].split('/');
            const urlInfo = `${fragmentUrl.join('/')}/client-info`;
            navigate(urlInfo);
        },
        payment: () => {
            const fragmentUrl = location.pathname.split('/payment')[0].split('/');
            const urlInfo = `${fragmentUrl.join('/')}/client-info`;
            navigate(urlInfo);
        },
        sumary: () => {
            const fragmentUrl = location.pathname.split('/sumary')[0].split('/');
            let urlInfo = `${fragmentUrl.join('/')}/face-recognition`;

            if (!availableRecognition){
                urlInfo = `${fragmentUrl.join('/')}/client-vinculation`;
            }

            navigate(urlInfo);
        },
        clientVinculation: () => {
            const fragmentUrl = location.pathname.split('/client-vinculation')[0].split('/');
            const urlInfo = `${fragmentUrl.join('/')}/payment`;
            navigate(urlInfo);
        },
        faceRecognition: () => {
            const fragmentUrl = location.pathname.split('/face-recognition')[0].split('/');
            const urlInfo = `${fragmentUrl.join('/')}/client-vinculation`;
            navigate(urlInfo);
        },
        productDetails: () => {
            const fragmentUrl = location.pathname.split('/product-details')[0].split('/');
            let urlInfo = `${fragmentUrl.join('/')}/face-recognition`;;

            if (!availableRecognition){
                urlInfo = `${fragmentUrl.join('/')}/client-vinculation`;
            }

            navigate(urlInfo);
        }

    };

    if (location.pathname.includes('proposal')) {
        goBack.proposal();
    }

    if (location.pathname.includes('info')) {
        goBack.info();
    }

    if (location.pathname.includes('client-info')) {
        goBack.clientInfo();
    }

    if (location.pathname.includes('inspection')) {
        goBack.inspection();
    }

    if (location.pathname.includes('payment')) {
        goBack.payment();
    }

    if (location.pathname.includes('sumary')) {
        goBack.sumary();
    }

    if (location.pathname.includes('recognition')) {
        goBack.recognition();
    }

    if (location.pathname.includes('client-vinculation')) {
        goBack.clientVinculation();
    }

    if (location.pathname.includes('face-recognition')) {
        goBack.faceRecognition();
    }

    if (location.pathname.includes('product-details')) {
        goBack.productDetails();
    }
};

const QuoteTitleHeader = (props) => {
    const classes = useStyles(props);
    const location = useLocation();

    return (
        <Grid item xs={12} className={classes.titleContainer} onClick={() => onClick(props.to, props.onClick, location, props.riskType, props.availableRecognition)}>
            <KeyboardArrowLeft className="backIcon" />
            <h1 className={classes.title}>{props.title}</h1>
        </Grid>
    );
};

export default QuoteTitleHeader;
