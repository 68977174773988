import React, { useEffect, useState } from "react";
import { change, Field, reduxForm } from "redux-form";
import moment from "moment";
import {
    renderTextField,
    renderSelectField,
    renderDateField,
    renderCheckbox,
} from "../renderInputsForm";
import { Button, Grid, MenuItem } from "@material-ui/core";
import settings from "../../../settings";

const getMaritalStatus = {
    "CASADO(A)": "Casado",
    "DIVORCIADO(A)": "Divorciado",
    "SOLTERO(A)": "Soltero",
};

// todo fill other options
const getDocumentType = {
    SLVIDCardV1: "DUI",
};

const validate = (values, props) => {
    const { document } = props;

    const errors = {};
    const requiredFields = [
        "name",
        "surname",
        "select1",
        "documentNumber",
        "birthday",
        "maritalStatus",
        "address",
        "checkbox0",
        "phone",
        "cityOfBirth",
        "nationality",
        "department",
        "municipios",
    ];

    if(!document){
        requiredFields.push("dateOfExpiry","dateOfIssuing")
    }
 
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = "Requerido";
        }
    });

    if (values.dateOfExpiry) {
        const startDate = moment(values.dateOfIssuing);
        const endDate = moment(values.dateOfExpiry);

        if (startDate.isAfter(endDate)) {
            errors.dateOfIssuing = 'La fecha de expedición no puede ser mayor a la fecha de expiración.';
        }
    }

    return errors;
};

const emailValidation = (value) =>
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) || value === ""
        ? "Debe ser un mail válido"
        : undefined;

const emailCheck = (email) => (value) =>
    value && value !== email ? `Los correos deben coincidir` : undefined;

const ClientInfoForm = ({
    handleSubmit,
    submitting,
    document,
    quotationScreenValues,
    initialize,
    valid,
    onDataReturn,
    goToNextPage,
    reset,
}) => {
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [municipalities, setMunicipalities] = useState([]);
    const [email, setEmail] = useState(null);

    const name =
        document && document.name ? document.name : quotationScreenValues.name;

    useEffect(() => {
        handleDepartments();
    }, []);

    useEffect(() => {
        if (document) {
            let recognitionData = null;
            if (
                quotationScreenValues.clientInfo &&
                quotationScreenValues.isFullfilledClientInfoForm
            ) {
                const _document = quotationScreenValues.clientInfo;
                setSelectedDepartment(_document.department);
                recognitionData = {
                    ..._document,
                };
            } else {
                recognitionData = {
                    name,
                    surname: document.surname,
                    documentNumber: document.personalNumber
                        ? document.personalNumber.replace("-", "")
                        : "",
                    birthday: moment(
                        document.dateOfBirth,
                        "DD/MM/YYYY",
                        true
                    ).format(),
                    address: document.address,
                    email: document.email,
                    maritalStatus: getMaritalStatus[document.maritalStatus],
                    select1: getDocumentType[document.documentType],
                    spouseName:
                        document.spouseName &&
                        document.spouseSurname &&
                        document.spouseName !== "CORIDE" &&
                        document.spouseSurname !== "WARE" &&
                        document.spouseName !== "coride" &&
                        document.spouseSurname !== "ware"
                            ? `${document.spouseName} ${document.spouseSurname}`
                            : "",
                    dateOfIssuing: moment(
                        document.dateOfIssuing,
                        "DD/MM/YYYY",
                        true
                    ).format(),
                    dateOfExpiry: moment(
                        document.dateOfExpiry,
                        "DD/MM/YYYY",
                        true
                    ).format(),
                    cityOfBirth: document.cityOfBirth,
                    nationality: document.nationality,
                };
            }
            setEmail(recognitionData?.email);
            initialize({
                ...recognitionData,
            });
        }
    }, [document]);

    useEffect(() => {
        if (selectedDepartment) {
            handleMunicipalities();
        }
    }, [selectedDepartment]);

    const handleSubmits = async (values) => {
        console.log(values);
        await onDataReturn(values);
        reset();
        goToNextPage();
    };

    const handleDepartments = async () => {
        const res = await fetch(
            `${settings.listsUrlApi}departamentos/?readonly=1&label=name&value=code`
        ).then((res) => res.json());

        setDepartments(res);
    };

    const handleSelectDepartment = (e) => {
        setSelectedDepartment(e.target.value);
    };

    const handleMunicipalities = async () => {
        const res = await fetch(
            `${settings.listsUrlApi}municipios/?value=_id&label=name&readonly=1&codeDep=${selectedDepartment}`
        ).then((res) => res.json());
        setMunicipalities(res);
    };

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    const documentTypes = ["DUI", "Pasaporte", "Carnet de Residencia"];

    const maritalStatus = ["Casado", "Divorciado", "Soltero", "Otro"];

    return (
        <form onSubmit={handleSubmit(handleSubmits)}>
            <Grid container>
                <Grid item xs={12} style={{ padding: 16 }}>
                    <Grid item style={{ marginBottom: 20 }}>
                        <Field
                            name="name"
                            label="Nombres"
                            component={renderTextField}
                            custom={{ tabIndex: 1 }}
                        />
                    </Grid>
                    <Grid item style={{ marginBottom: 20 }}>
                        <Field
                            name="surname"
                            label="Apellidos"
                            component={renderTextField}
                            custom={{ tabIndex: 2 }}
                            style={{ marginBottom: 105 }}
                        />
                    </Grid>
                    <Grid item style={{ marginBottom: 20 }}>
                        <Field
                            name="select1"
                            label="Tipo de documento"
                            component={renderSelectField}
                            custom={{ tabIndex: 3 }}
                        >
                            {documentTypes.map((d, i) => (
                                <MenuItem key={`${d}__${i}`} value={d}>
                                    {d}
                                </MenuItem>
                            ))}
                        </Field>
                    </Grid>
                    <Grid item style={{ marginBottom: 20 }}>
                        <Field
                            name="documentNumber"
                            label="Número de documento"
                            component={renderTextField}
                            custom={{ tabIndex: 4 }}
                        />
                    </Grid>
                    <Grid item style={{ marginBottom: 20 }}>
                        <Field
                            name="birthday"
                            label="Fecha de nacimiento"
                            component={renderDateField}
                            custom={{ tabIndex: 5 }}
                        />
                    </Grid>
                    <Grid item style={{ marginBottom: 20 }}>
                        <Field
                            name="cityOfBirth"
                            label="Lugar de Nacimiento"
                            component={renderTextField}
                            custom={{ tabIndex: 11 }}
                        />
                    </Grid>
                    <Grid item style={{ marginBottom: 20 }}>
                        <Field
                            name="maritalStatus"
                            label="Estado civil"
                            component={renderSelectField}
                            custom={{ tabIndex: 6 }}
                        >
                            {maritalStatus.map((m, i) => (
                                <MenuItem key={`${m}__${i}`} value={m}>
                                    {m}
                                </MenuItem>
                            ))}
                        </Field>
                    </Grid>
                    <Grid item style={{ marginBottom: 20 }}>
                        <Field
                            name="nationality"
                            label="Nacionalidad"
                            component={renderTextField}
                            custom={{ tabIndex: 12 }}
                        />
                    </Grid>
                    <Grid item style={{ marginBottom: 20 }}>
                        <Field
                            name="address"
                            label="Dirección"
                            component={renderTextField}
                            custom={{ tabIndex: 7 }}
                        />
                    </Grid>
                    <Grid item style={{ marginBottom: 20 }}>
                        <Field
                            name="department"
                            label="Departamentos"
                            component={renderSelectField}
                            custom={{ tabIndex: 13 }}
                            onChange={handleSelectDepartment}
                        >
                            {departments.map((d, i) => (
                                <MenuItem
                                    key={`${d.value}__${i}`}
                                    value={d.value}
                                >
                                    {d.label}
                                </MenuItem>
                            ))}
                        </Field>
                    </Grid>
                    <Grid item style={{ marginBottom: 20 }}>
                        <Field
                            name="municipios"
                            label="Municipios"
                            component={renderSelectField}
                            custom={{ tabIndex: 14 }}
                        >
                            {municipalities.map((d, i) => (
                                <MenuItem
                                    key={`${d.value}__${i}`}
                                    value={d.value}
                                >
                                    {d.label}
                                </MenuItem>
                            ))}
                        </Field>
                    </Grid>
                    <Grid item style={{ marginBottom: 20 }}>
                        <Field
                            name="dateOfIssuing"
                            label="Fecha de expedición del documento"
                            component={renderDateField}
                            custom={{ tabIndex: 15, disabled: !!document }}
                        />
                    </Grid>
                    <Grid item style={{ marginBottom: 20 }}>
                        <Field
                            name="dateOfExpiry"
                            label="Fecha de expiración del documento"
                            component={renderDateField}
                            custom={{ tabIndex: 16, disabled: !!document }}
                        />
                    </Grid>
                    <Grid item style={{ marginBottom: 20 }}>
                        <Field
                            name="spouseName"
                            label="Nombre de cónyuge"
                            component={renderTextField}
                            custom={{ tabIndex: 17, disabled: !!document }}
                        />
                    </Grid>
                    {/* <Grid item style={{ marginBottom: 20 }}>
            <Field
              name="email"
              label="Mail"
              component={renderTextField}
              custom={{ tabIndex: 8 }}
              validate={[emailValidation]}
              onChange={handleChangeEmail}
            />
          </Grid>
          <Grid item style={{ marginBottom: 20 }}>
            <Field
              name="mailCheck"
              label="Confirmación de mail"
              component={renderTextField}
              custom={{ tabIndex: 9 }}
              validate={[emailValidation, emailCheck(email)]}
            />
          </Grid> */}
                    <Grid item style={{ marginBottom: 20 }}>
                        <Field
                            name="phone"
                            label="Teléfono"
                            component={renderTextField}
                            custom={{ tabIndex: 10 }}
                        />
                    </Grid>
                    <Grid item style={{ margin: "50px 0px" }}>
                        <Field
                            name="checkbox0"
                            label="Acepto los términos y condiciones, incluyendo las políticas de privacidad, reconozco que los datos suministrados son ciertos en todas sus partes, por haber sido ingresados por mi persona. Asimismo, acepto por este medio que dicha información sea utilizada para servir de base para el análisis, aceptación del riesgo y posterior expedición de la póliza."
                            component={renderCheckbox}
                            custom={{ tabIndex: 18 }}
                        />
                    </Grid>
                    <Grid item style={{ marginBottom: 20 }}>
                        <Button
                            style={{
                                width: "100%",
                                padding: "19px 15px",
                                maxWidth: 231,
                                marginTop: 52,
                            }}
                            disabled={submitting || !valid}
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Siguiente
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default reduxForm({ form: "ClientInfoForm", validate })(ClientInfoForm);
