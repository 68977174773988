//todo: obviusly remove this :), get this value from speedform dependency selector (deparment, municipality)
const getValue = {
    department: {
        1: "AHUACHAPAN",
        2: "SANTA ANA",
        6: "SAN SALVADOR",
        5: "CHALATENANGO",
        3: "SONSONATE",
        4: "LA LIBERTAD",
        9: "CABAÑAS",
        11: "USULUTAN",
        8: "LA PAZ",
        10: "SAN VICENTE",
        14: "LA UNION",
        7: "CUSCATLAN",
        13: "SAN MIGUEL",
        12: "MORAZAN"
    },
    municipality: {
        "624745e506bc4d1f53e8d73a": "TACUBA",
        "624745e506bc4d1f53e8d73b": "TURIN",
        "624745e506bc4d1f53e8d730": "AHUACHAPAN",
        "624745e506bc4d1f53e8d731": "JUJUTLA",
        "624745e506bc4d1f53e8d732": "ATIQUIZAYA",
        "624745e506bc4d1f53e8d733": "CONCEPCION DE ATACO",
        "624745e506bc4d1f53e8d734": "EL REFUGIO",
        "624745e506bc4d1f53e8d735": "GUAYMANGO",
        "624745e506bc4d1f53e8d736": "APANECA",
        "624745e506bc4d1f53e8d737": "SAN FRANCISCO MENENDEZ",
        "624745e506bc4d1f53e8d738": "SAN LORENZO",
        "624745e506bc4d1f53e8d739": "SAN PEDRO PUXTLA",
        "624745e506bc4d1f53e8d73c": "CANDELARIA DE LA FRONTERA",
        "624745e506bc4d1f53e8d73d": "CHALCHUAPA",
        "624745e506bc4d1f53e8d73e": "COATEPEQUE",
        "624745e506bc4d1f53e8d73f": "EL CONGO",
        "624745e506bc4d1f53e8d740": "EL PORVENIR",
        "624745e506bc4d1f53e8d741": "MASAHUAT",
        "624745e506bc4d1f53e8d742": "METAPAN",
        "624745e506bc4d1f53e8d743": "SAN ANTONIO PAJONAL",
        "624745e506bc4d1f53e8d744": "SAN SEBASTIAN SALITRILLO",
        "624745e506bc4d1f53e8d745": "SANTA ANA",
        "624745e506bc4d1f53e8d746": "SANTA ROSA GUACHIPILIN",
        "624745e506bc4d1f53e8d747": "SANTIAGO DE LA FRONTERA",
        "624745e506bc4d1f53e8d748": "TEXISTEPEQUE",
        "624745e506bc4d1f53e8d74a": "ARMENIA",
        "624745e506bc4d1f53e8d74b": "CALUCO",
        "624745e506bc4d1f53e8d74c": "CUISNAHUAT",
        "624745e506bc4d1f53e8d74d": "IZALCO",
        "624745e506bc4d1f53e8d74e": "JUAYUA",
        "624745e506bc4d1f53e8d74f": "NAHUIZALCO",
        "624745e506bc4d1f53e8d749": "ACAJUTLA",
        "624745e506bc4d1f53e8d750": "NAHULINGO",
        "624745e506bc4d1f53e8d751": "SALCOATITAN",
        "624745e506bc4d1f53e8d752": "SAN ANTONIO DEL MONTE",
        "624745e506bc4d1f53e8d753": "SAN JULIAN",
        "624745e506bc4d1f53e8d754": "SANTA CATARINA MASAHUAT",
        "624745e506bc4d1f53e8d755": "SANTA ISABEL ISHUATAN",
        "624745e506bc4d1f53e8d756": "SANTO DOMINGO DE GUZMAN",
        "624745e506bc4d1f53e8d757": "SONSONATE",
        "624745e506bc4d1f53e8d758": "SONZACATE",
        "624745e506bc4d1f53e8d7b0": "ANTIGUO CUSCATLAN",
        "624745e506bc4d1f53e8d7b1": "CHILTIUPAN",
        "624745e506bc4d1f53e8d7b2": "CIUDAD ARCE",
        "624745e506bc4d1f53e8d7b3": "COLON",
        "624745e506bc4d1f53e8d7b4": "COMASAGUA",
        "624745e506bc4d1f53e8d7b5": "HUIZUCAR",
        "624745e506bc4d1f53e8d7b6": "JAYAQUE",
        "624745e506bc4d1f53e8d7b7": "JICALAPA",
        "624745e506bc4d1f53e8d7b8": "LA LIBERTAD",
        "624745e506bc4d1f53e8d7b9": "SANTA TECLA",
        "624745e506bc4d1f53e8d7ba": "NUEVO CUSCATLAN",
        "624745e506bc4d1f53e8d7bb": "SAN JUAN OPICO",
        "624745e506bc4d1f53e8d7bc": "QUEZALTEPEQUE",
        "624745e506bc4d1f53e8d7bd": "SACACOYO",
        "624745e506bc4d1f53e8d7be": "SAN JOSE VILLANUEVA",
        "624745e506bc4d1f53e8d7bf": "SAN MATIAS",
        "624745e506bc4d1f53e8d7c0": "SAN PABLO TACACHICO",
        "624745e506bc4d1f53e8d7c1": "TALNIQUE",
        "624745e506bc4d1f53e8d7c2": "TAMANIQUE",
        "624745e506bc4d1f53e8d7c3": "TEOTEPEQUE",
        "624745e506bc4d1f53e8d7c4": "TEPECOYO",
        "624745e506bc4d1f53e8d7c5": "ZARAGOZA",
        "624745e506bc4d1f53e8d7c6": "AGUA CALIENTE",
        "624745e506bc4d1f53e8d7c7": "ARCATAO",
        "624745e506bc4d1f53e8d7c8": "AZACUALPA",
        "624745e506bc4d1f53e8d7c9": "CANCASQUE",
        "624745e506bc4d1f53e8d7ca": "CHALATENANGO",
        "624745e506bc4d1f53e8d7cb": "CITALA",
        "624745e506bc4d1f53e8d7cc": "COMAPALA",
        "624745e506bc4d1f53e8d7cd": "CONCEPCION QUEZALTEPEQUE",
        "624745e506bc4d1f53e8d7ce": "DULCE NOMBRE DE MARIA",
        "624745e506bc4d1f53e8d7cf": "EL CARRIZAL",
        "624745e506bc4d1f53e8d7d0": "EL PARAISO",
        "624745e506bc4d1f53e8d7d1": "LA LAGUNA",
        "624745e506bc4d1f53e8d7d2": "LA PALMA",
        "624745e506bc4d1f53e8d7d3": "LA REINA",
        "624745e506bc4d1f53e8d7d4": "LAS VUELTAS",
        "624745e506bc4d1f53e8d7d5": "NUEVA CONCEPCION",
        "624745e506bc4d1f53e8d7d6": "NUEVA TRINIDAD",
        "624745e506bc4d1f53e8d7d7": "NOMBRE DE JESUS",
        "624745e506bc4d1f53e8d7d8": "OJOS DE AGUA",
        "624745e506bc4d1f53e8d7d9": "POTONICO",
        "624745e506bc4d1f53e8d7da": "SAN ANTONIO DE LA CRUZ",
        "624745e506bc4d1f53e8d7db": "SAN ANTONIO LOS RANCHOS",
        "624745e506bc4d1f53e8d7dc": "SAN FERNANDO",
        "624745e506bc4d1f53e8d7dd": "SAN FRANCISCO LEMPA",
        "624745e506bc4d1f53e8d7de": "SAN FRANCISCO MORAZAN",
        "624745e506bc4d1f53e8d7df": "SAN IGNACIO",
        "624745e506bc4d1f53e8d7e0": "SAN ISIDRO LABRADOR",
        "624745e506bc4d1f53e8d7e1": "LAS FLORES",
        "624745e506bc4d1f53e8d7e2": "SAN LUIS DEL CARMEN",
        "624745e506bc4d1f53e8d7e3": "SAN MIGUEL DE MERCEDES",
        "624745e506bc4d1f53e8d7e4": "SAN RAFAEL",
        "624745e506bc4d1f53e8d7e5": "SANTA RITA",
        "624745e506bc4d1f53e8d7e6": "TEJUTLA",
        "624745e506bc4d1f53e8d7f7": "AGUILARES",
        "624745e506bc4d1f53e8d7f8": "APOPA",
        "624745e506bc4d1f53e8d7f9": "AYUTUXTEPEQUE",
        "624745e506bc4d1f53e8d7fa": "CUSCATANCINGO",
        "624745e506bc4d1f53e8d7fb": "CIUDAD DELGADO",
        "624745e506bc4d1f53e8d7fc": "EL PAISNAL",
        "624745e506bc4d1f53e8d7fd": "GUAZAPA",
        "624745e506bc4d1f53e8d7fe": "ILOPANGO",
        "624745e506bc4d1f53e8d7ff": "MEJICANOS",
        "624745e506bc4d1f53e8d800": "NEJAPA",
        "624745e506bc4d1f53e8d801": "PANCHIMALCO",
        "624745e506bc4d1f53e8d802": "ROSARIO DE MORA",
        "624745e506bc4d1f53e8d803": "SAN MARCOS",
        "624745e506bc4d1f53e8d804": "SAN MARTIN",
        "624745e506bc4d1f53e8d805": "SAN SALVADOR",
        "624745e506bc4d1f53e8d806": "SANTIAGO TEXACUANGOS",
        "624745e506bc4d1f53e8d807": "SANTO TOMAS",
        "624745e506bc4d1f53e8d808": "SOYAPANGO",
        "624745e506bc4d1f53e8d809": "TONACATEPEQUE",
        "624745e506bc4d1f53e8d7e9": "EL CARMEN",
        "624745e506bc4d1f53e8d7e7": "COJUTEPEQUE",
        "624745e506bc4d1f53e8d7ea": "EL ROSARIO",
        "624745e506bc4d1f53e8d7eb": "MONTE SAN JUAN",
        "624745e506bc4d1f53e8d7ef": "SAN JOSE GUAYABAL",
        "624745e506bc4d1f53e8d7f0": "SAN PEDRO PERULAPAN",
        "624745e506bc4d1f53e8d7ed": "SAN BARTOLOME PERULAPIA",
        "624745e506bc4d1f53e8d7ee": "SAN CRISTOBAL",
        "624745e506bc4d1f53e8d7ec": "ORATORIO DE CONCEPCION",
        "624745e506bc4d1f53e8d7e8": "CANDELARIA",
        "624745e506bc4d1f53e8d7f5": "SUCHITOTO",
        "624745e506bc4d1f53e8d7f6": "TENANCINGO",
        "624745e506bc4d1f53e8d7f4": "SANTA CRUZ MICHAPA",
        "624745e506bc4d1f53e8d7f3": "SANTA CRUZ ANALQUITO",
        "624745e506bc4d1f53e8d7f2": "SAN RAMON",
        "624745e506bc4d1f53e8d7f1": "SAN RAFAEL CEDROS",
        "624745e506bc4d1f53e8d80e": "MERCEDES LA CEIBA",
        "624745e506bc4d1f53e8d810": "PARAISO DE OSORIO",
        "624745e506bc4d1f53e8d816": "SAN JUAN TALPA",
        "624745e506bc4d1f53e8d815": "SAN JUAN NONUALCO",
        "624745e506bc4d1f53e8d818": "SAN LUIS LA HERRADURA",
        "624745e506bc4d1f53e8d819": "SAN LUIS TALPA",
        "624745e506bc4d1f53e8d80a": "ZACATECOLUCA",
        "624745e506bc4d1f53e8d811": "SAN ANTONIO MASAHUAT",
        "624745e506bc4d1f53e8d80c": "EL ROSARIO",
        "624745e506bc4d1f53e8d813": "SAN FRANCISCO CHINAMECA",
        "624745e506bc4d1f53e8d817": "SAN JUAN TEPEZONTES",
        "624745e506bc4d1f53e8d814": "SAN PEDRO MASAHUAT",
        "624745e506bc4d1f53e8d80f": "OLOCUILTA",
        "624745e506bc4d1f53e8d80b": "CUYULTITAN",
        "624745e506bc4d1f53e8d812": "SAN EMIGDIO",
        "624745e506bc4d1f53e8d80d": "JERUSALEN",
        "624745e506bc4d1f53e8d81d": "SANTA MARIA OSTUMA",
        "624745e506bc4d1f53e8d81e": "SANTIAGO NONUALCO",
        "624745e506bc4d1f53e8d81b": "SAN PEDRO NONUALCO",
        "624745e506bc4d1f53e8d81c": "SAN RAFAEL OBRAJUELO",
        "624745e506bc4d1f53e8d81f": "TAPALHUACA",
        "624745e506bc4d1f53e8d81a": "SAN MIGUEL TEPEZONTES",
        "624745e506bc4d1f53e8d824": "JUTIAPA",
        "624745e506bc4d1f53e8d823": "ILOBASCO",
        "624745e506bc4d1f53e8d826": "SENSUNTEPEQUE",
        "624745e506bc4d1f53e8d821": "DOLORES",
        "624745e506bc4d1f53e8d827": "TEJUTEPEQUE",
        "624745e506bc4d1f53e8d822": "GUACOTECTI",
        "624745e506bc4d1f53e8d828": "VICTORIA",
        "624745e506bc4d1f53e8d820": "CINQUERA",
        "624745e506bc4d1f53e8d825": "SAN ISIDRO",
        "624745e506bc4d1f53e8d82a": "GUADALUPE",
        "624745e506bc4d1f53e8d82e": "SAN LORENZO",
        "624745e506bc4d1f53e8d82d": "SAN ILDEFONSO",
        "624745e506bc4d1f53e8d82c": "SAN ESTEBAN CATARINA",
        "624745e506bc4d1f53e8d82b": "SAN CAYETANO ISTEPEQUE",
        "624745e506bc4d1f53e8d829": "APASTEPEQUE",
        "624745e506bc4d1f53e8d82f": "SAN SEBASTIAN",
        "624745e506bc4d1f53e8d831": "SANTA CLARA",
        "624745e506bc4d1f53e8d835": "VERAPAZ",
        "624745e506bc4d1f53e8d834": "TEPETITAN",
        "624745e506bc4d1f53e8d832": "SANTO DOMINGO",
        "624745e506bc4d1f53e8d833": "TECOLUCA",
        "624745e506bc4d1f53e8d830": "SAN VICENTE",
        "624745e506bc4d1f53e8d75a": "BERLIN",
        "624745e506bc4d1f53e8d75d": "EL TRIUNFO",
        "624745e506bc4d1f53e8d75b": "CALIFORNIA",
        "624745e506bc4d1f53e8d759": "ALEGRIA",
        "624745e506bc4d1f53e8d75c": "CONCEPCION BATRES",
        "624745e506bc4d1f53e8d765": "OZATLAN",
        "624745e506bc4d1f53e8d764": "NUEVA GRANADA",
        "624745e506bc4d1f53e8d766": "PUERTO EL TRIUNFO",
        "624745e506bc4d1f53e8d763": "MERCEDES UMAÑA",
        "624745e506bc4d1f53e8d761": "JUCUAPA",
        "624745e506bc4d1f53e8d762": "JUCUARAN",
        "624745e506bc4d1f53e8d767": "SAN AGUSTIN",
        "624745e506bc4d1f53e8d75f": "ESTANZUELAS",
        "624745e506bc4d1f53e8d760": "JIQUILISCO",
        "624745e506bc4d1f53e8d769": "SAN DIONISIO",
        "624745e506bc4d1f53e8d76f": "USULUTAN",
        "624745e506bc4d1f53e8d76c": "SANTA MARIA",
        "624745e506bc4d1f53e8d76a": "SAN FRANCISCO JAVIER",
        "624745e506bc4d1f53e8d76d": "SANTIAGO DE MARIA",
        "624745e506bc4d1f53e8d76b": "SANTA ELENA",
        "624745e506bc4d1f53e8d76e": "TECAPAN",
        "624745e506bc4d1f53e8d75e": "EREGUAYQUIN",
        "624745e506bc4d1f53e8d768": "SAN BUENAVENTURA",
        "624745e506bc4d1f53e8d78a": "EL ROSARIO",
        "624745e506bc4d1f53e8d785": "CACAOPERA",
        "624745e506bc4d1f53e8d78b": "GUALOCOCTI",
        "624745e506bc4d1f53e8d786": "CHILANGA",
        "624745e506bc4d1f53e8d787": "CORINTO",
        "624745e506bc4d1f53e8d789": "EL DIVISADERO",
        "624745e506bc4d1f53e8d784": "ARAMBALA",
        "624745e506bc4d1f53e8d788": "DELICIAS DE CONCEPCION",
        "624745e506bc4d1f53e8d790": "LOLOTIQUILLO",
        "624745e506bc4d1f53e8d78e": "JOCOAITIQUE",
        "624745e506bc4d1f53e8d78f": "JOCORO",
        "624745e506bc4d1f53e8d792": "OSICALA",
        "624745e506bc4d1f53e8d78d": "JOATECA",
        "624745e506bc4d1f53e8d795": "SAN FERNANDO",
        "624745e506bc4d1f53e8d793": "PERQUIN",
        "624745e506bc4d1f53e8d791": "MEANGUERA",
        "624745e506bc4d1f53e8d797": "SAN ISIDRO",
        "624745e506bc4d1f53e8d799": "SENSEMBRA",
        "624745e506bc4d1f53e8d798": "SAN SIMON",
        "624745e506bc4d1f53e8d794": "SAN CARLOS",
        "624745e506bc4d1f53e8d796": "SAN FRANCISCO GOTERA",
        "624745e506bc4d1f53e8d78c": "GUATAJIAGUA",
        "624745e506bc4d1f53e8d79d": "YOLOAIQUIN",
        "624745e506bc4d1f53e8d79b": "TOROLA",
        "624745e506bc4d1f53e8d79c": "YAMABAL",
        "624745e506bc4d1f53e8d79a": "SOCIEDAD",
        "624745e506bc4d1f53e8d771": "CHAPELTIQUE",
        "624745e506bc4d1f53e8d772": "CHINAMECA",
        "624745e506bc4d1f53e8d770": "CAROLINA",
        "624745e506bc4d1f53e8d779": "NUEVA GUADALUPE",
        "624745e506bc4d1f53e8d77c": "SAN ANTONIO DEL MOSCO",
        "624745e506bc4d1f53e8d775": "COMACARAN",
        "624745e506bc4d1f53e8d774": "CIUDAD BARRIOS",
        "624745e506bc4d1f53e8d77a": "NUEVO EDEN DE SAN JUAN",
        "624745e506bc4d1f53e8d777": "LOLOTIQUE",
        "624745e506bc4d1f53e8d77b": "QUELEPA",
        "624745e506bc4d1f53e8d77e": "SAN JORGE",
        "624745e506bc4d1f53e8d77d": "SAN GERARDO",
        "624745e506bc4d1f53e8d778": "MONCAGUA",
        "624745e506bc4d1f53e8d776": "EL TRANSITO",
        "624745e506bc4d1f53e8d780": "SAN MIGUEL",
        "624745e506bc4d1f53e8d781": "SAN RAFAEL ORIENTE",
        "624745e506bc4d1f53e8d783": "ULUAZAPA",
        "624745e506bc4d1f53e8d782": "SESORI",
        "624745e506bc4d1f53e8d773": "CHIRILAGUA",
        "624745e506bc4d1f53e8d77f": "SAN LUIS DE LA REINA",
        "624745e506bc4d1f53e8d7a7": "MEANGUERA DEL GOLFO",
        "624745e506bc4d1f53e8d7a1": "CONCHAGUA",
        "624745e506bc4d1f53e8d79f": "SAN ALEJO",
        "624745e506bc4d1f53e8d79e": "LA UNION",
        "624745e506bc4d1f53e8d7a0": "YUCUAIQUIN",
        "624745e506bc4d1f53e8d7a8": "SANTA ROSA DE LIMA",
        "624745e506bc4d1f53e8d7a3": "SAN JOSE",
        "624745e506bc4d1f53e8d7a6": "BOLIVAR",
        "624745e506bc4d1f53e8d7a5": "YAYANTIQUE",
        "624745e506bc4d1f53e8d7a4": "EL CARMEN",
        "624745e506bc4d1f53e8d7a2": "INTIPUCA",
        "624745e506bc4d1f53e8d7ae": "POLOROS",
        "624745e506bc4d1f53e8d7ad": "CONCEPCION DE ORIENTE",
        "624745e506bc4d1f53e8d7af": "LISLIQUE",
        "624745e506bc4d1f53e8d7aa": "ANAMOROS",
        "624745e506bc4d1f53e8d7ab": "NUEVA ESPARTA",
        "624745e506bc4d1f53e8d7ac": "EL SAUCE",
        "624745e506bc4d1f53e8d7a9": "PASAQUINA"
    }
}

export default getValue;
