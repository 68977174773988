import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    Grid,
    Button,
    NativeSelect
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import { useSelector, useDispatch } from "react-redux";
import { navigate, useLocation } from '@reach/router';
import { Close, ExpandMore, Lock } from "@material-ui/icons";
import { getDecisionTables, postSaveQuotation } from "../../actions/serviceActions";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        borderRadius: 20,
        flexDirection: "column",
        justifyContent: "space-between",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    close: {
        position: "absolute",
        top: 0,
        right: 0,
    },
    title: {
        marginTop: 24,
    },
    planCoverages: {
        display: "flex",
        flexDirection: "column",
        width: "100%",

        "& > div": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "8px 0px",
            fontSize: 13,
            lineHeight: "15px",
            letterSpacing: 0.15,
            color: "#4B4B4B",
            "&.tax-group": {
                borderBottom: `1px solid ${theme.palette.secondary.main}`,
                paddingBottom: "8px",
                "&:last-child > p": {
                    backgroundColor: theme.palette.primary.main,
                    color: "#fff",
                },
            },
            "& span": {
                display: "inline-flex",
                alignItems: "center",
                textAlign: "left",
                "& i": {
                    marginRight: 4,
                    "& svg.available": {
                        color: "green",
                    },
                    "& svg.not-available": {
                        color: "red",
                    },
                },
            },
            "& p": {
                display: "flex",
                alignItems: "center",
                "& i": {
                    marginLeft: 5,
                    cursor: "pointer",
                    height: 16,
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "50%",
                    "& svg": {
                        width: 16,
                        height: 16,
                        fill: "#fff",
                    },
                },
            },
            "&.tax-group p": {
                margin: 0,
                borderRadius: 16,
                padding: "5px 10px",
            },
            "& b": {
                fontWeight: 600,
                fontSize: 12,
                textAlign: "right",
            },
        },
    },
}));
const arraySort = (a, b) => {
    if (a.name > b.name) {
        return 1;
    }
    if (a.name < b.name) {
        return -1;
    }
    // a must be equal to b
    return 0;
};

const StyledAccordion = withStyles({
    root: {
        margin: "0px !important",
        boxShadow: "none !important",
        "& .MuiAccordionDetails-root": {
            padding: "0px 16px",
        },
    },
})(Accordion);

const StyledAccordionSummary = withStyles((theme) => ({
    root: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: 0.15,
        color: theme.palette.secondary.main,
        textAlign: "left",
        flexDirection: "row-reverse",
    },
    content: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    expanded: {
        margin: "0px !important",
        minHeight: "54px !important",
    },
    expandIcon: {
        color: theme.palette.secondary.main,
        margin: 0,
        padding: "0px 12px",
    },
}))(AccordionSummary);

const formaterMoney = (value) => {
    return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }).format(value)
}

const reFloatMoney = (value) => {
    return parseFloat(value.replace(/,/g, ''))
}

const ModalEditCoverages = ({
    open,
    handleClose,
    product,
    coverageGroups,
    quotation,
    insurance
}) => {
    const [selectedCoverages, setSelectedCoverages] =  useState({});
    const classes = useStyles();
    const dispatch = useDispatch();
    const decisionTable = useSelector((state) => state.decisionTable);
    const location = useLocation();

    useEffect(() => {
        if (product && product.sourceDocId) {
            dispatch(
                getDecisionTables(product.sourceDocId, {
                    age: quotation.quotationScreenValues.age,
                })
            );
        }

    }, [product]);

    useEffect(() => {
        if(coverageGroups){
            let coveragesToSet = {};
            for(let coverageGroup of coverageGroups){
                for(let coverage of coverageGroup.coverages){
                    if(coverage.value === 'NO'){
                        coveragesToSet[coverage.internCode] = 'NO';
                    }
                }
            }
            setSelectedCoverages({...selectedCoverages, ...coveragesToSet});
        }
    },[]);

    const handleCheckboxChange = (event, coverage) => {
        const valueTOSelect =   decisionTable.data[coverage.internCode] &&
                                decisionTable.data[coverage.internCode][0].SA &&
                                formaterMoney(decisionTable.data[coverage.internCode][0].SA)
        const result = {
            ...selectedCoverages,
            [coverage.internCode]: event.target.checked ? valueTOSelect : "NO"
        }
        setSelectedCoverages(result);
    };

    const handleCoverageValueChange = (coverageInternCode, coverageValue) => {
        setSelectedCoverages({...selectedCoverages,
            [coverageInternCode]: reFloatMoney(coverageValue)
        })
    }

    const handleSave = async () => {
        const newQuotation = {
            lead: {
                email: quotation.quotationScreenValues.lead
            },
            item: {
                ...quotation.quotationScreenValues,
                riskType: quotation.riskType,
                insuranceId: quotation.insuranceId,
                selectedCoverages
            }
        };
        const resultQuote = await dispatch(postSaveQuotation(newQuotation,quotation.riskType));
        sessionStorage.setItem(`quotation::quote::${quotation.riskType}`, JSON.stringify({ plan: {}, quotation: resultQuote }));
        localStorage.setItem('quotation::id', resultQuote._id);

        if (location.pathname.includes('embed')) {
            navigate(`/embed/quote/${quotation.riskType.toLowerCase()}/${insurance.quotationScreenId}/${insurance._id}/${insurance.formBuilderId}/${resultQuote._id}/proposal`);
        } else {
            navigate(`/quote/${quotation.riskType.toLowerCase()}/${insurance.quotationScreenId}/${insurance._id}/${insurance.formBuilderId}/${resultQuote._id}/proposal`);
        }

        handleClose();
    };

    return (
        <Dialog className={classes.modal} open={open} onClose={handleClose}>
            <DialogTitle className={classes.title}>
                <div className={"card-selected"}>
                    <span>{product.name}</span>
                    <IconButton className={classes.close}>
                        <Close onClick={handleClose} />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent style={{ width: 500 }}>
                {coverageGroups &&
                    coverageGroups
                        .sort(arraySort)
                        .map((coverageGroup, index) => (
                            <StyledAccordion
                                defaultExpanded={true}
                            >
                                <StyledAccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls={`proposal_panel_${index}`}
                                    id={`proposal_panel_${index}`}
                                >
                                    <span>{coverageGroup.name}</span>
                                </StyledAccordionSummary>
                                <AccordionDetails>
                                    <div className={classes.planCoverages}>
                                        {coverageGroup.coverages.map(
                                            (cv, coverageIndex) => {
                                                return coverageGroup.id ===
                                                    "tax" ? (
                                                    <div className="tax-group">
                                                        <span>{cv.name}</span>
                                                        <p>
                                                            <b>{cv.value}</b>
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <span>
                                                            {cv.valueType ===
                                                            "5" ? (
                                                                <Checkbox
                                                                    checked={
                                                                        selectedCoverages[cv.internCode] !== 'NO'
                                                                    }
                                                                    onClick={(event) =>
                                                                        handleCheckboxChange(event,cv)
                                                                    }
                                                                />
                                                            ) : (
                                                                <i
                                                                    style={{
                                                                        padding: 8,
                                                                    }}
                                                                >
                                                                    <Lock />
                                                                </i>
                                                            )}
                                                            {cv.name}
                                                        </span>
                                                        {cv.valueType === "5" &&
                                                            selectedCoverages[cv.internCode] !== 'NO' &&
                                                            decisionTable &&
                                                            decisionTable.data &&
                                                            decisionTable.data[
                                                                cv.internCode
                                                            ] && (
                                                                <NativeSelect
                                                                    defaultValue={cv.value}
                                                                    inputProps={{
                                                                        name: 'SA',
                                                                        id: 'uncontrolled-native-SA',
                                                                    }}
                                                                    onChange = {(event) => {
                                                                        handleCoverageValueChange(cv.internCode, event.target.value);
                                                                    }}
                                                                >
                                                                    {decisionTable.data[cv.internCode].map((decision,index) => (
                                                                        <option
                                                                            value={formaterMoney(decision.SA)}
                                                                            key={index}
                                                                        >
                                                                            {formaterMoney(decision.SA)}
                                                                        </option>)
                                                                    )}
                                                                </NativeSelect>
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </AccordionDetails>
                            </StyledAccordion>
                        ))}
                <Grid container justify="center" style={{ marginTop: "30px" }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSave()}
                        style={{
                            marginLeft: "10px",
                        }}
                    >
                        ACEPTAR
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ModalEditCoverages;
