import React from 'react';
import { Grid, makeStyles, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import IndexsHook from './hooks/indexsHook';
import TagHook from './hooks/tagsHook';
import DataToSendHook from './hooks/dataToSendHook';
import { getWithRemote } from '../../actions/dynamicActions';
import LayoutLoading from '../layout/layoutLoading';
import QuoteTitle from '../quote/quoteTitle';
import QuoteContainer from './quoteContainer';
import { isValidEmail } from '../../utils';
import { navigate } from '@reach/router';
import { postSaveQuotation } from '../../actions/serviceActions';

const useStyles = makeStyles(() => ({
  appContainer: {
    paddingLeft: 8,
    paddingRight: 8,
    width: '100%'
  }
}));

const DynamicForm = ({ data, riskType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [year, setYear] = React.useState(false);
  const dynamicReducer = useSelector(state => state.dynamicReducer);

  const { groupIndex, fieldIndex, changeIndex, changeIndexByTag } = IndexsHook();
  //Data comes in
  const { dataToSend, handleChangeDataToSend } = DataToSendHook(data.groups, data.riskType, data._id);
  const { tags, handleChangeTag, handleChangeTagCancelled } = TagHook(data.groups);
  
  const group = data.groups[groupIndex];
  let isValid = group && dataToSend && dataToSend.groups[groupIndex].fields[fieldIndex] &&  dataToSend.groups[groupIndex].fields[fieldIndex].value && isValidEmail(dataToSend.groups[groupIndex].fields[fieldIndex].value);

  if (!group) {
    const lastGroupIndex = data.groups.length - 1;
    const lastFieldIndex = data.groups[lastGroupIndex].fields.length - 1;
    changeIndexByTag(lastFieldIndex, lastGroupIndex);
  }

  const handleSelect = (name, id, isBoolean) => {
    if (group.fields[fieldIndex + 1] && group.fields[fieldIndex + 1].remote) {
      const remote = year ? `${group.fields[fieldIndex + 1].remote}${id}/${year}` : `${group.fields[fieldIndex + 1].remote}${name}`;

      dispatch(getWithRemote(remote));
    }

    if (!year) setYear(name);

    //const value = isBoolean ? id : id || name;
    const value = isBoolean ? id : name;
    const fieldName = dataToSend.groups[groupIndex].fields[fieldIndex].name;
    handleChangeTag(name, group.groupName, group.groupLabel, fieldIndex, groupIndex, fieldName);
    changeIndex(group);
    handleChangeDataToSend(fieldIndex, groupIndex, value);
  }

  const handleCloseTag = (tag) => {
    let url;
    
    if (tag.fieldIndex === 0 && tag.groupIndex === 0) setYear(false);

    if (dataToSend.groups[tag.groupIndex].fields[tag.fieldIndex - 1]) {
      const remote = dataToSend.groups[tag.groupIndex].fields[tag.fieldIndex].remote;
      const id = dataToSend.groups[tag.groupIndex].fields[tag.fieldIndex - 1].value;
      url = `${remote}${id}/${year}`

      if(remote !== null) {
        dispatch(getWithRemote(url));
      }
    }

    changeIndexByTag(tag.fieldIndex, tag.groupIndex)
    handleChangeTagCancelled(tag.fieldIndex, tag.name, tag.value);
  };

  const renderTags = () => {
    return Object.keys(tags).map((key) => {
      if (tags[key].tagsArray.length !== 0 || tags[key].index <= groupIndex) {
        return (
          <QuoteTitle key={tags[key].label} title={tags[key].label} tags={tags[key].tagsArray} handleCloseTag={handleCloseTag} />
        )
      }
      return null;
    })
  };

  const goToNextPage = () => {
    const quoteBody = buildQuoteBody(dataToSend);
    dispatch(postSaveQuotation(quoteBody, riskType));
    navigate(`/quote/${riskType.toLowerCase()}/${data._id}/proposal`);
  };

  if (dynamicReducer.isFetching) return <LayoutLoading />

  return (
    <Grid container justify="center" className={classes.appContainer}>
      <Grid item xs={12} sm={9} md={7} className="cardAppContainer">
        {tags && renderTags()}
        {group &&
          <QuoteContainer
            handleSelect={handleSelect}
            name={group.fields[fieldIndex].name}
            label={group.fields[fieldIndex].label}
            dataType={group.fields[fieldIndex].dataType}
            controlType={group.fields[fieldIndex].controlType}
            options={group.fields[fieldIndex].options}
            data={dynamicReducer.data}
          />}
          <Grid container style={{ justifyContent: 'center'}} className="contentForm">
            <Button 
              variant="contained" 
              color="primary" 
              onClick={goToNextPage}
              disabled={!isValid} 
              >
              COTIZAR
            </Button>
          </Grid> 
      </Grid>
    </Grid>
  );
}

const buildQuoteBody = dataToSend => {
   const quoteBody = {};

  dataToSend.groups.forEach(group => {
    group.fields.forEach(field => {
      quoteBody[field.name] = field.value;
    })
  });

   quoteBody.riskType = dataToSend.riskType;
   quoteBody.insuranceId = '5efd04280811034a18f1d277';

   return quoteBody;
}

export default DynamicForm;