import {
  getInsuranceAPI,
  getInsuranceByIdAPI,
} from "../sevices/insuranceService";
import { RESET_INSURANCE } from "../actions/types";

export const getInsurance = (token) => async (dispatch) => {
  await dispatch(getInsuranceAPI(token));
};

export const getInsuranceById = (Id) => async (dispatch) => {
  await dispatch(getInsuranceByIdAPI(Id));
};

export const resetInsurance = () => (dispatch) => {
  dispatch({ type: RESET_INSURANCE });
};
