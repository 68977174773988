import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    width: '100%'
  },
  display: {
    position: "absolute",
    top: 2,
    left: 0,
    bottom: 2,
    background: "white",
    pointerEvents: "none",
    right: 50,
    display: "flex",
    alignItems: "center"
  },
  input: {
    width: '100%'
  }
}));

function InputComponent({ defaultValue, inputRef, ...props }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(() => props.value || defaultValue);

  const handleChange = event => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div className={classes.root}>
      <input
        className={classes.input}
        ref={inputRef}
        {...props}
        onChange={handleChange}
        value={value}
      />
    </div>
  );
};

export default InputComponent;